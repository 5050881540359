import React from "react";

import "../../../../Style/ChargerProfile/ChargerComponents/ChargerOpinion/ChargerOpinion.css";
import ChargerRating from "./ChargerOpinionComponents/ChargerRating";

const chargerOpinion = props => {
  return (
    <div className="ChargerOpinionBox">
      <ChargerRating
        onRatingChange={props.onRatingChange}
        votes={props.votes}
        charger={props.charger}
      />
      <div className="ChargerOpinionSumBox">
          <div>
          <div className="ChargerRatingSum">
              {global.translate('Average rating')}<div>{(props.charger.rating || 0).toFixed(2)}</div>
            </div>
          <div className="ChargerRatingSum">
            {global.translate('Rating count')} <div>{props.charger.vote_count}</div>
          </div>
        </div>
          <div>
            <div className="ChargerRatingSum">
              {global.translate('Comments')} <div>{props.charger.comments_count}</div>
            </div>
            <div className="ChargerRatingSum">
              {global.translate('Charging sessions')} <div>{props.charger.reservations_count}</div>
            </div>
          </div>
      </div>
    </div>
  );
};

export default chargerOpinion;
