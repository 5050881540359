import axios from 'axios';
import { history } from '../index';
import Actions from './store/actions';
import { store } from './store/reducer';

class Api {
  static currentUser = null;

  static axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 10000,
  });

  static checkToken = token => new Promise(async (resolve, _) => {
    Api.axiosInstance
      .get('/user', { headers: { Authorization: `Bearer ${token}` } })
      .then(resp => resolve({ code: resp.status, data: resp.data }));
  });

  static get headers() {
    return Api.currentUser == null ? {} : { Authorization: `Bearer ${Api.currentUser.token}` };
  }

  static get = url => new Promise(async (resolve, _) => {
    Api.axiosInstance
      .get(url, {
        headers: Api.headers,
      })
      .then(resp => resolve({ code: resp.status, data: resp.data }));
  });

  static post = (url, data) => new Promise(async (resolve, _) => {
    Api.axiosInstance
      .post(url, data, {
        headers: Api.headers,
      })
      .then(resp => resolve({ code: resp.status, data: resp.data }));
  });

  static patch = (url, data) => new Promise(async (resolve, _) => {
    Api.axiosInstance
      .patch(url, data, {
        headers: Api.headers,
      })
      .then(resp => resolve({ code: resp.status, data: resp.data }));
  });

  static delete = url => new Promise(async (resolve, _) => {
    Api.axiosInstance
      .delete(url, {
        headers: Api.headers,
      })
      .then(resp => resolve({ code: resp.status, data: resp.data }));
  });

  static get_raw = url => new Promise(async (resolve, _) => {
    axios.get(url).then(resp => resolve({ code: resp.status, data: resp.data })).catch(err => console.log(err));
  });
}

Api.axiosInstance.interceptors.response.use(null, (error) => {
  const response = error.response || { status: 0 };

  console.log(`HTTP ERROR ${response.status}`);

  switch (response.status) {
    case 401:
      store.dispatch({ type: Actions.LOGOUT });
      history.push('/login');
      break;

    default:
      break;
  }

  return { data: response.data, status: response.status };
});

export default Api;
