import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import Api from "../../HOC/Api";
import Actions from "../../HOC/store/actions";
import Permission from "../../HOC/permission";
import Swal from "sweetalert2";
import { moveTo } from '../Map/Marker';

import ChargerProfileNav from "./ChargerProfileNav/ChargerProfileNav";
import ChargerDescription from "./ChargerProfileComponents/ChargerDescription/ChargerDescription";
import ChargerComment from "./ChargerProfileComponents/ChargerComment/ChargerComment";
import ChargerReservation from "./ChargerProfileComponents/ChargerReservation/ChargerReservation";
import ChargerFoto from "./ChargerProfileComponents/ChargerFoto/ChargerFoto";
import ChargerOpinion from "./ChargerProfileComponents/ChargerOpinion/ChargerOpinion";

//import "../../Style/ChargerProfile/ChargerProfile.css";

class ChargerProfile extends Component {
  constructor(props) {
    super(props);
    this.reservationDate = React.createRef();
    this.reservationStartAtHour = React.createRef();
    this.reservationEndAtHour = React.createRef();
    this.reservationEndAtMinutes = React.createRef();
    this.reservationStartAtMinutes = React.createRef();
    this.sendComment = React.createRef();
    this.fileRef = React.createRef();
    this.userNickname = props.currentUser.nick
  }

  get chargerId() {
    return this.props.match.params.id;
  }

  state = {
    charger: {},
    votes: { speed: 0, cost: 0, service: 0, available: 0 },
    errors: [],
    isSendingComment: false
  };


  uploadPhotoHandler = async () => {
    const data = new FormData();
    data.append('photo[file]', this.fileRef.current.files[0]);
    const response = await Api.post(`/chargers/photo/${this.chargerId}`, data);
    console.log(response);
    this.getCharger()
  }

  onRatingChange = async (category, value) => {
    const newState = { ...this.state.votes };
    newState[category] = value;
    this.setState({ votes: newState });

    let totalRating =
      newState.speed * newState.cost * newState.service * newState.available;
    if (totalRating !== 0) {
      const result = await Api.post("/votes", {
        charger_id: this.state.charger.id,
        speed: newState.speed,
        cost: newState.cost,
        service: newState.service,
        available: newState.available
      });
      if (result.code === 201) {
        this.props.showNotification("Pomyślnie dodano ocenę!");
        this.getCharger();
      }
    }
  };

  reservationSetHandler = async () => {
    const reservation = this.reservationDate.current.value;
    const reservationStart = this.reservationStartAtHour.current.value;
    const reservationEnd = this.reservationEndAtHour.current.value;
    const reservationEndMinutes = this.reservationEndAtMinutes.current.value;
    const reservationStartMinutes = this.reservationStartAtMinutes.current
      .value;

    const startData = `${reservation} ${reservationStart}:${reservationStartMinutes}`;
    const endData = `${reservation} ${reservationEnd}:${reservationEndMinutes}`;

    const result = await Api.post("/reservations", {
      charger_id: this.state.charger.id,
      start: startData,
      end: endData,
      user: this.state.userNickname
    });

    if (result.code === 201) {
      this.props.showNotification("Pomyślnie zarezerwowano!");
      this.getCharger();
    } else if (result.code === 422) {
      let errorsData = result.data.errors
      if (errorsData.length === 0) {
        this.setState({errors: ['Proszę wprowadzić dane']})
      } else {
        const errors = errorsData.map(item => <li key={item}>{item.slice(5)}</li>)
        this.setState({errors: errors})
      }
    }
  };

  reservationCanceledHandler = id => {
    Swal.fire({
      title: "Czy napewno chcesz usunąć rezerwacje ?",
      type: "question",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: "Usuń",
      cancelButtonText: "Anuluj"
    }).then(async result => {
      if (result.value) {
        const canceled = await Api.get(`/reservations/${id}/cancel`);
        if (canceled.code === 200) {
          this.props.showNotification("Anulowano pomyślnie");
          this.getCharger();
        }
      }
    });
  };

  sendCommentHandler = async () => {
    this.setState({isSendingComment: true})
    const commentSend = this.sendComment.current.value;
    const comment = await Api.post("/comments", {
      content: commentSend,
      charger_id: this.state.charger.id
    });
    this.setState({isSendingComment: false})
    if (comment.code === 201) {
      this.props.showNotification("Pomyślnie dodano komentarz!");
      this.getCharger();
    }
  };

  deleteCommentHandler = async id => {
    const getCharger = { ...this.state.charger };
    const getComments = [...getCharger.comments];
    const commentIndex = getComments.findIndex(item => item.id === id);

    Swal.fire({
      title: "Czy napewno chcesz usunąć komentarz ?",
      type: "question",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: "Usuń",
      cancelButtonText: "Anuluj"
    }).then(async result => {
      if (result.value) {
    const response = await Api.delete(`/comments/${id}/`);
    if (response.code === 200) {
      this.props.showNotification("Usunięto pomyśle");
      getComments.splice(commentIndex, 1)
    getCharger.comments = getComments
    this.setState({
      charger: getCharger
    })
    }
  }})
  }

  getLikeHandler = async id => {
    const getCharger = { ...this.state.charger };
    const getComments = [...getCharger.comments];
    console.log(getComments);
    global.test = getComments;
    const commentIndex = getComments.findIndex(item => item.id === id);
    getComments[commentIndex] = {
      ...getComments[commentIndex],
      isliked: !getComments[commentIndex].isliked,
      likes:
        getComments[commentIndex].likes +
        (getComments[commentIndex].isliked === true ? -1 : 1)
    };
    getCharger.comments = getComments;
    console.log(commentIndex)
    this.setState({ charger: getCharger });
    console.log(getComments[commentIndex].isliked);
    if (getComments[commentIndex].isliked === true) {
      const response = await Api.post(`/comments/${id}/like`, {
        isliked: getComments
      });
      console.log(response.code);
    } else {
      const response = await Api.delete(`/comments/${id}/like`);
      console.log(response.code);
    }
    console.log("elo");
  };

  goToMapHandler = () => {
	this.props.history.push("/home")
	
	moveTo(this.state.charger.id, this.state.charger.lat, this.state.charger.lng);
  }

  getCharger = async () => {
    const chargers = await Api.get(`/chargers/${this.chargerId}`);
    console.log(chargers);
    console.log(this.chargerId);

    if (chargers.code === 200) {
      this.setState({
        charger: chargers.data,
        votes: {
          speed: chargers.data.my_vote.speed || 0,
          cost: chargers.data.my_vote.cost || 0,
          available: chargers.data.my_vote.available || 0,
          service: chargers.data.my_vote.service || 0
        } 
      });
    }
    else if(chargers.code === 404){
      Swal.fire({
        type: 'error',
        title: 'Bład wczytania strony',
        showConfirmButton: true,
        confirmButtonText: "Wróć",
      }).then(() => this.props.history.push('/home'));
    }
  };

  componentDidMount() {
    this.getCharger();
  }

  render()
  {
    return (
      <div className="ChargerContent MainWithGradient">
        <Permission userRequired />
        <ChargerProfileNav chargerId={this.chargerId} />
        <Switch>
          <Route
            path={`/charger/${this.chargerId}/comments`}
            component={() => (
              <ChargerComment
                charger={this.state.charger}
                newComment={this.sendComment}
                sendComment={this.sendCommentHandler}
                likeComment={this.getLikeHandler}
                deleteComment={this.deleteCommentHandler}
                isSendingComment={this.state.isSendingComment}
              />
            )}
          />
          <Route
            path={`/charger/${this.chargerId}/reservation`}
            component={() => (
              <ChargerReservation
                charger={this.state.charger}
                reservationDate={this.reservationDate}
                reservationStartAtHour={this.reservationStartAtHour}
                reservationEndAtHour={this.reservationEndAtHour}
                reservationEndAtMinutes={this.reservationEndAtMinutes}
                reservationStartAtMinutes={this.reservationStartAtMinutes}
                reservedClicked={this.reservationSetHandler}
                cancelClicked={this.reservationCanceledHandler}
                errors={this.state.errors}
              />
            )}
          />
          <Route
            path={`/charger/${this.chargerId}/fotos`}
            component={() => <ChargerFoto charger={this.state.charger} fileRef={this.fileRef} uploadPhotoHandler={this.uploadPhotoHandler} />}
          />
          <Route
            path={`/charger/${this.chargerId}/opinion`}
            component={() => (
              <ChargerOpinion
                charger={this.state.charger}
                onRatingChange={this.onRatingChange}
                votes={this.state.votes}
              />
            )}
          />
          <Route
            path={`/charger/${this.chargerId}`}
            component={() => (
              <ChargerDescription charger={this.state.charger} goToMap={this.goToMapHandler} />
            )}
            exact
          />
        </Switch>
      </div>
    );
  }
}

const STP = state => ({
  currentUser: state.currentUser
});

const DTP = dispatch => ({
  showNotification: text =>
    dispatch({ type: Actions.SHOW_NOTIFICATION, text: text })
});

export default connect(
  STP,
  DTP
)(ChargerProfile);
