import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import Progress from '@material-ui/core/CircularProgress';
import ChargerMarker from '../../assets/images/new_marker.svg';
import { MapObject } from './Map';
import Api from "../../HOC/Api";

class Marker extends Component
{
	state = {
		display: false,
		loading: false,
		name: '',
		address: ''
	}

	static closeOpenedInfo = null;
	static willOpenedId = null;
	static openHandlers = {};


	render = () => (
		<div className='ChargerMarker'>
			{this.props.comment === true ? <div>ddd</div> : null}
			<img onClick={this.markerOpen} alt='ChargerMarker' src={ChargerMarker} />
			{this.props.comments ? <div className='marker-circle'/> : null}
			{
				!this.state.display ? null :
				<div className='MarkerInfo'>
				{
					this.state.loading ?
					(
						<div className='MarkerInfoLoading'>
							<Progress />
						</div> 
					) :
					(
						<div>
							<b>{this.state.name}</b><br />
							{this.state.address}
						</div>
					)
				}
					<button onClick={() => this.props.history.push(`/charger/${this.props.id}`)}>
						{global.translate('Go to proile')}
					</button>
					<div onClick={this.markerClose} className='MarkerInfoClose'>
						x
					</div>
				</div>
			}
		</div>
	)

	markerOpen = async () => {
		if(this.state.display)
			return;

		if(Marker.closeOpenedInfo)
			Marker.closeOpenedInfo();

		MapObject.current.map_.panTo({ lat: this.props.lat, lng: this.props.lng });

		Marker.closeOpenedInfo = this.markerClose;

		this.setState({ loading: true, display: true });
		const result = await Api.get(`/chargers/${this.props.id}`);
		this.setState({ loading: false });

		if(result.code === 200)
			this.setState({ name: result.data.name, address: result.data.address })
	}

	markerClose = () => {
		Marker.closeOpenedInfo = null;
		this.setState({ display: false });
	}

	componentDidMount = () => {
		Marker.openHandlers[this.props.id] = this.markerOpen;

		if(this.props.id === Marker.willOpenedId){
			this.markerOpen();
			Marker.willOpenedId = null;
		}
	}
}

export const moveTo = (id, lat, lng) => {
	Marker.willOpenedId = id;
	MapObject.current.map_.panTo({lat: lat, lng: lng});
	MapObject.current.map_.setZoom(17);

	if(id in Marker.openHandlers)
		Marker.openHandlers[id]();
}

export default withRouter(Marker);