import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Link } from 'react-router-dom';
import { Tabs, Tab, withStyles } from '@material-ui/core';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import Api from '../../HOC/Api';
import UserReservationsList from './UserReservationsList';
import Actions from '../../HOC/store/actions';


const styles = () => ({
  indicator: {
    backgroundColor: 'white',
  },
});

class UserReservationsContainer extends Component {
  state = {
    currentReservations: [],
    expiredReservations: [],
    loadingCurrent: false,
    loadingExpired: false,
  };

  componentDidMount() {
    this.getReservations();
    this.getExpiredReservations();
  }

  getReservations = async () => {
    this.setState({ loadingCurrent: true });
    const reservations = await Api.get('/reservations');
    this.setState({
      currentReservations: reservations.data.reservations,
      loadingCurrent: false,
    });
  };

  getExpiredReservations = async () => {
    this.setState({ loadingExpired: true });
    const reservations = await Api.get('user/stats');
    this.setState({
      expiredReservations: reservations.data.reservations,
      loadingExpired: false,
    });
  };

  reservationClickHandler = (item) => {
    const { history } = this.props;
    history.push(`/charger/${item.charger_id}`);
  };

  cancelReservationHandler = (event, id) => {
    event.stopPropagation();
    Swal.fire({
      title: 'Czy napewno chcesz usunąć rezerwacje ?',
      type: 'question',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: 'Usuń',
      cancelButtonText: 'Anuluj',
    }).then(async (result) => {
      if (result.value) {
        const canceled = await Api.get(`/reservations/${id}/cancel`);
        if (canceled.code === 200) {
          this.props.showNotification('Anulowano pomyślnie');
          this.getReservations();
        }
      }
    });
  };

  getActiveTabValue = (pathname) => {
    if (pathname === '/user_reservation') {
      return `${pathname}/current`;
    }
    return pathname;
  };

  render() {
    const { classes, location } = this.props;
    const { expiredReservations, currentReservations, loadingCurrent, loadingExpired } = this.state;
    return (
      <div className="UserReservationBox MainWithGradient">
        <Tabs
          value={location.pathname}
          centered
          variant="fullWidth"
          classes={{
            indicator: classes.indicator,
          }}
        >
          <Tab
            label="Aktualne"
            value="/user_reservation"
            component={Link}
            to="/user_reservation"
          />
          <Tab
            label="Wygasłe"
            value="/user_reservation/expired"
            component={Link}
            to="/user_reservation/expired"
          />
        </Tabs>
        {location.pathname === '/user_reservation/expired' ? (
          <UserReservationsList reservations={expiredReservations} listTitle="Twoje wygasłe rezerwacje" onClickReservation={this.reservationClickHandler} isLoading={loadingExpired} />
        ) : (
          <UserReservationsList reservations={currentReservations} listTitle="Twoje aktualne rezerwacje" onClickReservation={this.reservationClickHandler} onClickCancelReservation={this.cancelReservationHandler} isLoading={loadingCurrent} />
        )}
      </div>
    );
  }
}

const DTP = dispatch => ({
  showNotification: text => dispatch({ type: Actions.SHOW_NOTIFICATION, text }),
});

export default withStyles(styles)(
  connect(
    null,
    DTP,
  )(UserReservationsContainer),
);

UserReservationsContainer.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  history: ReactRouterPropTypes.history.isRequired,
  classes: PropTypes.shape({
    indicator: PropTypes.string,
  }).isRequired,
  showNotification: PropTypes.func.isRequired,
};

UserReservationsContainer.defaultProps = {
  location: {
    pathname: '',
  },
};
