import React, { useState, useEffect } from 'react'
import "./Admin.css"
import Api from '../../HOC/Api'
import Pagination from './Pagination'
import Swal from "sweetalert2";

const formStart = React.createRef()
const formEnd = React.createRef()
const formUserId = React.createRef()
const formChargerId = React.createRef()

const ReservationsView = props => {

  const [reservations, setReservations] = useState([])
  const [page, setPage] = useState(1)
  const [pagination, setPagination] = useState({})
  const [orderBy, setOrderBy] = useState('id')
  const [order, setOrder] = useState('asc')
  const [search, setSearch] = useState('')

  const getReservations = async () => {
    const url = `/admin/reservations?page=${page}&order_by=${orderBy}&order=${order}&search=${search}`
    const result = await Api.get(url)
    setReservations(result.data.reservations)
    setPage(result.data.pagination.page)
    setPagination({
      total: result.data.pagination.count,
      pages: result.data.pagination.pages,
      items: result.data.pagination.items
    })
  }

  const uploadNew = async () => {
    const url = `/admin/reservations`
    const params = {
      user_id: formUserId.current.value,
      charger_id: formChargerId.current.value,
      start: formStart.current.value,
      end: formEnd.current.value
    }
    const response = await Api.post(url, params)
    console.log(response)
    if (response.code === 200) {
      alert('OK')
    } else {
      alert('Error')
    }
  }

  const destroyReservation = async id => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.value) {
        const url = `/admin/reservations/${id}`
        const response = await Api.delete(url)
        if (response.code === 400) {
          Swal.fire({
            type: 'error',
            title: 'Error'
          })
        }
        getReservations()
      }
    })
  }

  useEffect(() => {
    getReservations()
  }, [page, orderBy, order, search]) // eslint-disable-line

  const goToPageHandler = arg => {
    setPage(arg)
  }

  const mapping = reservations.map(reservation => {
    return (
      <tr key={reservation.id}>
        <td>{reservation.id}</td>
        <td>{reservation.charger_id}</td>
        <td>{reservation.user_id ? reservation.user_id : 'null'}</td>
        <td>{reservation.get_start}</td>
        <td>{reservation.get_end}</td>
        <td>{reservation.cancel ? 'true' : 'false'}</td>
        <td><span style={{cursor: 'pointer', color: 'red'}} onClick={() => destroyReservation(reservation.id)}>Destroy</span></td>
      </tr>
    )
  })

  const searchBox = (
    <div className="AdminSearch">
      <input placeholder="Search" value={search} onChange={event => setSearch(event.target.value)}/>
    </div>
  )

  const query = (
    <div className="AdminQuery">
      <select onChange={event => setOrderBy(event.target.value)} value={orderBy}>
        <option value='id'>ID</option>
        <option value='charger_id'>Charger</option>
        <option value='user_id'>User</option>
        <option value='start'>Start</option>
        <option value='end'>End</option>
        <option value='cancel'>Cancel</option>
      </select>
      <select onChange={event => setOrder(event.target.value)} value={order}>
        <option value='asc'>Ascending</option>
        <option value='desc'>Descending</option>
      </select>
    </div>
  )

  const newForm = (
    <div className="AdminForm">
      <h3>New reservation</h3>
      <table>
        <tbody>
          <tr>
            <td><label>User</label></td>
            <td><input type="number" ref={formUserId} placeholder="user_id" /></td>
          </tr>
          <tr>
            <td><label>Charger</label></td>
            <td><input type="number" ref={formChargerId} placeholder="charger_id"/></td>
          </tr>
          <tr>
            <td><label>Start</label></td>
            <td><input ref={formStart} placeholder="dd-mm-yyyy hh:mm" /></td>
          </tr>
          <tr>
            <td><label>End</label></td>
            <td><input ref={formEnd} placeholder="dd-mm-yyyy hh:mm" /></td>
          </tr>
        </tbody>
      </table>
      <button onClick={uploadNew}>Submit</button>
    </div>
  )

  return (
    <div className="AdminContainer">
      <div className="AdminBar">
        {searchBox}
        {query}
      </div>
      <div className="AdminTable">
        <table border='1' width='100%'>
          <thead>
            <tr>
              <th>ID</th>
              <th>Charger</th>
              <th>User</th>
              <th>Start</th>
              <th>End</th>
              <th>Cancel</th>
              <th>Destroy</th>
            </tr>
          </thead>
          <tbody>
            {mapping}
          </tbody>
        </table>
        {newForm}
      </div>
      <Pagination 
        page={page} 
        pagination={pagination}
        goToPageHandler={goToPageHandler}
      />
    </div>
  )
}

export default ReservationsView