import React from "react";

const reportChargerSelect = props => {
  return (
    <div className="ReportChargerSelect">
      <div>Gniazdo</div>
      <select onChange={props.change} multiple>
        {(props.plugs.plugs || []).map(item => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default reportChargerSelect;
