import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Progress from '@material-ui/core/CircularProgress';
import Logo from '../../assets/images/logo.png';
import Api from '../../HOC/Api';
import Actions from '../../HOC/store/actions';
import Permission from '../../HOC/permission';
import '../../Style/Login/Login.css';
import TextField from '@material-ui/core/TextField';

class Login extends Component {
  constructor(props) {
    super(props);

    this.usernameInput = React.createRef();
    this.passwordInput = React.createRef();
  }

  state = {
    loading: false,
  };

  loginIn = async () => {
    const username = this.usernameInput.current.querySelector('input').value;
    const password = this.passwordInput.current.querySelector('input').value;

    if (username === '' || password === '') {
      this.props.showNotification(global.translate('Enter login credentials'));
      return;
    }

    this.setState({ loading: true });

    const result = await Api.post('/sessions', {
      email: username,
      password,
    });

    if (result.code === 201) {
      const userData = { ...result.data.user, token: result.data.token };
      this.props.onAuth(userData);
      this.props.history.push('/home');
    } else if (result.code === 401) {
      this.props.showNotification(global.translate('Invalid login credentials'));
    } else {
      this.props.showNotification(global.translate('Server connection error'));
    }

    this.setState({ loading: false });
  };

  keyDownHandler = (event) => {
    if (event.keyCode === 13) this.loginIn();
  };

  render() {
    return (
      <div className="LoginWindow">
        <Permission userDeny />
        <div>
          {/* <div className='LoginWindowBackground' /> */}
          {!this.state.loading ? null : (
            <Progress className="LoginLoading" classes={{ root: this.props.classes.progress }} />
          )}
          <div className="LoginWindowContent">
            <div className="LoginLogo">
              <img alt="Logo" src={Logo} />
            </div>
            <div className="LoginHeader">{global.translate('Chargelity')}</div>

            <div className="LoginHeader2">{global.translate('LOGIN')}</div>
            <div className="LoginForm">
              <div className="LoginFormTextField1">
                <TextField
                  ref={this.usernameInput}
                  onKeyDown={this.keyDownHandler}
                  type="email"
                  margin="normal"
                  label="email"
                />
              </div>
              <div className="LoginFormTextField2">
                <TextField
                  ref={this.passwordInput}
                  onKeyDown={this.keyDownHandler}
                  type="password"
                  label="hasło"
                />
              </div>
              <div className="Button1">
                <button onClick={this.loginIn}>{global.translate('Login me')}</button>
              </div>
              <div className="Button2">
                <button onClick={() => this.props.history.push('/register')}>{global.translate('Sign up')}</button>
              </div>
              <Link to="/reset_password">{global.translate('Forgot password')}</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const DTP = dispatch => ({
  onAuth: user => dispatch({
    type: Actions.AUTH,
    user,
  }),
  showNotification: text => dispatch({ type: Actions.SHOW_NOTIFICATION, text }),
});

const styles = theme => ({
  progress: {
    margin: theme.spacing(2),
  },
});

export default withStyles(styles)(
  connect(
    null,
    DTP,
  )(Login),
);
