import React from "react";

import "../../../../Style/ChargerProfile/ChargerComponents/ChargerDescription/ChargerDescription.css";
import basicCharger from "../../../../assets/images/basic_charger.png";
import Location from "@material-ui/icons/MyLocationOutlined"
import Charger from '../../../../assets/images/electric-charge.svg'

const chargerDescription = props => {
  return (
    <div className="ChargerDescBox">
      <div className="ChargerDescContent">
      <div className='ChargerDescTopBox'>
        <img src={Charger} alt=" " className="ChargerDescPhoto"/>
          <div className="ChargerDescTopTitle">
            <div className="ChargerDescTitle">{props.charger.name}</div>
            <div className="ChargerDescAddress">{props.charger.address}</div>
            <button onClick={props.goToMap}><div>{global.translate('Show on map')}</div></button>
          </div>
          </div>
          <div className="ChargerDescContent">
        <div className="ChargerDescTitleBottom">
          <div>{global.translate('Socket type')}</div>
          <div className="ChargerDescList">
              {(props.charger.plugs || []).map((item, index) => (
                <div key={index}>
                  {item.name}
                </div>
              ))}
          </div>
        </div>
        <div className="ChargerDescTitleBottom">
          <div>{global.translate('Opening hours')}</div>
          <div className="ChargerDescList">
            {props.charger.all_day === true
              ? global.translate('All day')
              : `${props.charger.get_open_from} - ${props.charger.get_open_to}`}
          </div>
        </div>
        <div className="ChargerDescTitleBottom">
          <div>{global.translate('Address')}</div>
          <div className="ChargerDescList">{props.charger.address}</div>
        </div>
        <div className="ChargerDescTitleBottom">
          <div>{global.translate('Price')}</div>
          <div className="ChargerDescList">{props.charger.price}</div>
        </div>
        <div className="ChargerDescTitleBottom">
          <div>{global.translate('Description')}</div>
          <div className="ChargerDescList">{props.charger.description}</div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default chargerDescription;
