class NavigationHelper {
  constructor(directionsService, directionsRenderer) {
    this.directionsService = directionsService;
    this.directionsRenderer = directionsRenderer;
  }

  static deg2rad = deg => deg * (Math.PI / 180);

  static calculateDistanceBetweenLocationsInKm = (lat1, lng1, lat2, lng2) => {
    const R = 6371; // Radius of the earth in km
    const dLat = NavigationHelper.deg2rad(lat2 - lat1);
    const dLng = NavigationHelper.deg2rad(lng2 - lng1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
      + Math.cos(NavigationHelper.deg2rad(lat1))
        * Math.cos(NavigationHelper.deg2rad(lat2))
        * Math.sin(dLng / 2)
        * Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d;
  };

  findChargersInRange = (lat, lng, range, chargers = []) => {
    const chargersFound = [];
    for (let i = 0; i < chargers.length; i++) {
      if (
        NavigationHelper.calculateDistanceBetweenLocationsInKm(
          chargers[i].lat,
          chargers[i].lng,
          lat,
          lng,
        ) < range
      ) {
        chargersFound.push(chargers[i]);
      }
    }
    return chargersFound;
  };

  makeRoute = async (from, to, chargers) => {
    const waypoints = chargers.map(c => ({
      location: {
        lat: c.lat,
        lng: c.lng,
      },
    }));

    const routeResult = await this.makeDirectionsRequest(from, to, waypoints);
    if (routeResult) {
      this.directionsRenderer.setDirections(routeResult);
      return routeResult;
    }
    return null;
  };

  getChargersOnRoute = (routeSteps, chargingIntervalInKm, allChargers) => {
    let distanceFromLastCharging = 0;
    const nearestChargers = [];
    routeSteps.forEach((currentStep) => {
      distanceFromLastCharging += currentStep.distance.value;
      if (distanceFromLastCharging / 1000 >= chargingIntervalInKm * 0.8) {
        const chargersFound = this.findChargersInRange(
          currentStep.end_point.lat(),
          currentStep.end_point.lng(),
          20,
          allChargers,
        );
        if (chargersFound.length) {
          nearestChargers.push(chargersFound[0]);
          distanceFromLastCharging = 0;
        }
      }
    });
    const uniqueNearestChargers = [...new Set(nearestChargers)];
    return uniqueNearestChargers;
  };

   getRouteWithChargers = async (from, to, chargingInterval = 10, chargers = []) => {
    if (chargingInterval < 10) {
      chargingInterval = 10;
    }
    let finalRoute = null;
    try {
      const routeResult = await this.makeDirectionsRequest(from, to);
      const routeSteps = routeResult.routes[0].legs[0].steps; // steps of route with distance, duration etc.
      const chargersOnRoute = this.getChargersOnRoute(routeSteps, chargingInterval, chargers);
      finalRoute = await this.makeRoute(from, to, chargersOnRoute);
      return finalRoute.routes[0];
    } catch (err) {
      console.log('err :', err);
      return null;
    }
  };

  makeDirectionsRequest = (origin, destination, waypoints) => new Promise((resolve, reject) => {
    this.directionsService.route(
      {
        origin,
        destination,
        waypoints,
        travelMode: 'DRIVING',
      },
      (response, status) => {
        if (status === 'OK') {
          resolve(response);
        } else {
          reject(new Error(status));
        }
      },
    );
  });

  drawDirections = (directions) => {
    this.directionsRenderer.setDirections(directions);
  };

  clearDirections = () => {
    this.directionsRenderer.set('directions', null);
  }

  getGoogleMapsLinkFromRoute = (route) => {
    let link = 'https://www.google.com/maps/dir/?api=1';
    const { legs } = route;
    link += `&origin=${legs[0].start_address}&destination=${legs[legs.length - 1].end_address}&travelmode=driving`;
    if (legs.length > 1) {
      link += '&waypoints='
      for (let i = 1; i < legs.length; i++) {
        link += `${legs[i].start_address}|`;
      }
    }
    return encodeURI(link);
  }

  getDistanceFromRoute = (route) => {
    let totalDistance = 0;
    const { legs } = route;
    legs.forEach((l) => {
      totalDistance += l.distance.value;
    });
    return Math.round(totalDistance * 0.001);
  };
}


export default NavigationHelper;
