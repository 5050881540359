import React, { Component } from 'react';
import { connect } from 'react-redux';

import '../../Style/Notification/Notification.css';

class Notification extends Component
{
	render(){
		return(
			<div className="Notification" style={{ opacity: this.props.notificationShow ? 1 : 0 }}>
				<div>
					<div>
						{this.props.notificationText}
					</div>
				</div>
			</div>
		);
	}
}

const STP = (state) => ({
	notificationShow: state.notificationShow,
	notificationText: state.notificationText
});

export default connect(STP, null)(Notification);