import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Router } from "react-router-dom";

import { Provider } from 'react-redux';
import { store } from './HOC/store/reducer';
import Api from "./HOC/Api";
import Actions from "./HOC/store/actions";
import LanguageData from './text.json'

import './Style/Global.css';

export const history = require("history").createBrowserHistory()

setInterval(async () => {
	const currentState = store.getState();

	if(currentState.currentUser == null)
		return;

  const result = await Api.get('/conversations/refresh_unread');
	const isInMessages = history.location.pathname.startsWith('/messages');

  if (result.code === 200) {
    store.dispatch({type: Actions.SET_RESERVATION, reservation: result.data.reservation})
  }

  if(result.code === 200)
		if(result.data.unread === true){
			if(isInMessages){
				const convGetter = store.getState().conversationGetter;
				if(convGetter)
					convGetter();
			}
			else{
				if(currentState.newMessages === false){
					store.dispatch({ type: Actions.SHOW_NOTIFICATION, text: 'Otrzymałeś wiadomość' });
					store.dispatch({ type: Actions.SET_NEW_MESSAGES });
				}
			}
		}
}, 10000);

const start = async () => {
	const token = localStorage.getItem('userToken');

	if(token !== undefined){
		const result = await Api.checkToken(token);

		if(result.code === 200){
			const user = {...result.data.user, token: token }
			store.dispatch({ type: Actions.AUTH, user: user });
		}
		else if(result.code !== 0){
			store.dispatch({ type: Actions.LOGOUT });
		}
	}

	ReactDOM.render(
		<Provider store={store}>
			<Router history={history}>
				<App />
			</Router>
		  </Provider>,
	  document.getElementById("root")
	);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

global.getCurrentTimeToMinutes = () => {
	const currentTime = new Date();

	return `${currentTime.getDate().toString().padStart(2, 0)}.${(currentTime.getMonth() + 1).toString().padStart(2, 0)}.${currentTime.getFullYear()} ${currentTime.getHours().toString().padStart(2, 0)}:${currentTime.getMinutes().toString().padStart(2, 0)}`;
}

global.getTimeStringFromUnix = (unix) => {
	const currentTime = new Date(unix * 1);

	return `${currentTime.getDate().toString().padStart(2, 0)}.${(currentTime.getMonth() + 1).toString().padStart(2, 0)}.${currentTime.getFullYear()} ${currentTime.getHours().toString().padStart(2, 0)}:${currentTime.getMinutes().toString().padStart(2, 0)}`;
}

global.getDateString = (date) => `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, 0)}-${date.getDate().toString().padStart(2, 0)}`;

const getLanguage = () => {
	const language = navigator.language.slice(0,2)
	if (!language) return 'en'
	return language
}

global.translate = text => {
	const language = getLanguage()
	if (language === 'en') {
		const alt = LanguageData.en[text]
		if (!alt) return text
		return alt
	}
	const translation = LanguageData[language][text]
	if (!translation) {
		console.warn(`Translation of '${text}' missing`)
		return text
	}
	return translation
}



start();