import React, { useState, useEffect } from 'react'
import "./Admin.css"
import Api from '../../HOC/Api'
import Pagination from './Pagination'
import Swal from "sweetalert2";

const fId = React.createRef()
const fName = React.createRef()
const fAddress = React.createRef()
const fAccepted = React.createRef()
const fCity = React.createRef()
const fDescription = React.createRef()
const fLat = React.createRef()
const fLng = React.createRef()
const fOpenFrom = React.createRef()
const fOpenTo = React.createRef()
const fPhone = React.createRef()
const fWorking = React.createRef()
const fUserId = React.createRef()
const fPostalCode = React.createRef()
const fFlagId = React.createRef()
const fPrice = React.createRef()

const CarsView = props => {

  const [chargers, setChargers] = useState([])
  const [page, setPage] = useState(1)
  const [pagination, setPagination] = useState({})
  const [orderBy, setOrderBy] = useState('id')
  const [order, setOrder] = useState('asc')
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [mode, setMode] = useState('new')

  const getChargers = async () => {
    if (!loading) {
      setLoading(true)
      const url = `/admin/chargers?page=${page}&order_by=${orderBy}&order=${order}&search=${search}`
      const result = await Api.get(url)
      if (result.code === 200) {
        setLoading(false)
        setChargers(result.data.chargers)
        setPage(result.data.pagination.page)
        setPagination({
          total: result.data.pagination.count,
          pages: result.data.pagination.pages,
          items: result.data.pagination.items
        })
      } else {
        alert('Error')
      }
     
    }
  }

  const uploadCharger = async () => {
    let url = `/admin/chargers`
    if (mode === 'edit') {
      url += `/${fId.current.value}`
    }
    const params = {
      accepted: fAccepted.current.checked,
      working: fWorking.current.checked,
      name: fName.current.value,
      city: fCity.current.value,
      address: fAddress.current.value,
      description: fDescription.current.value,
      lat: fLat.current.value,
      lng: fLng.current.value,
      phone: fPhone.current.value,
      open_from: fOpenFrom.current.value,
      open_to: fOpenTo.current.value,
      postal_code: fPostalCode.current.value,
      price: fPrice.current.value,
      flag_id: fFlagId.current.value,
      user_id: fUserId.current.value
    }
    let response = null
    if (mode === 'new') {
      response = await Api.post(url, params)
    }
    if (mode === 'edit') {
      response = await Api.patch(url, params)
    }

    if (response.code === 201) {
      alert('OK')
    } else {
      alert('Error')
    }

    getChargers()
  }

  const switchToNew = () => {
    setMode('new')
    fId.current.value = null
  }

  const setFormCharger = charger => {
    fId.current.value = charger.id
    fAccepted.current.checked = charger.accepted
    fWorking.current.checked = charger.working
    fName.current.value = charger.name
    fCity.current.value = charger.city
    fAddress.current.value = charger.address
    fDescription.current.value = charger.description
    fLat.current.value = charger.lat
    fLng.current.value = charger.lng
    fPhone.current.value = charger.phone
    fOpenFrom.current.value = charger.get_open_from
    fOpenTo.current.value = charger.get_open_to
    fPostalCode.current.value = charger.postal_code
    fPrice.current.value = charger.price
    fFlagId.current.value = charger.flag_id
    fUserId.current.value = charger.user_id
    setMode('edit')
  }

  const destroyCharger = async id => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.value) {
        const url = `/admin/chargers/${id}`
        const response = await Api.delete(url)
        if (response.code !== 200) {
          console.log(response)
          Swal.fire({
            type: 'error',
            title: 'Error'
          })
        }
        getChargers()
      }
    })
  }

  useEffect(() => {
    getChargers()
  }, [search, orderBy, order, page]) // eslint-disable-line


  let mapping = null
  if (chargers) {
    mapping = chargers.map(charger => {
      return (
        <tr key={charger.id}>
          <td>{charger.id}</td>
          <td>{charger.name}</td>
          <td>{charger.user_id}</td>
          <td>{charger.accepted ? 'true' : 'false'}</td>
          <td>{charger.working ? 'true' : 'false'}</td>
          <td>{charger.city}</td>
          <td>{charger.address}</td>
          <td>{charger.get_open_from}</td>
          <td>{charger.get_open_to}</td>
          <td><span style={{ cursor: 'pointer', color: 'red' }} onClick={() => destroyCharger(charger.id)}>Destroy</span></td>
          <td><span style={{ cursor: 'pointer', color: 'green' }} onClick={() => setFormCharger(charger)}>Edit</span></td>
        </tr>
      )
    })
  }


  const searchBox = (
    <div className="AdminSearch">
      <input placeholder="Search" value={search} onChange={event => setSearch(event.target.value)} />
    </div>
  )

  const query = (
    <div className="AdminQuery">
      <select onChange={event => setOrderBy(event.target.value)} value={orderBy}>
        <option value='id'>ID</option>
        <option value='name'>Name</option>
        <option value='city'>City</option>
        <option value='from'>From</option>
        <option value='to'>To</option>
        <option value='phone'>Phone</option>
        <option value='rating'>Rating</option>
        <option value='accepted'>Accepted</option>
        <option value='working'>Working</option>
      </select>
      <select onChange={event => setOrder(event.target.value)} value={order}>
        <option value='asc'>Ascending</option>
        <option value='desc'>Descending</option>
      </select>
    </div>
  )

  const newForm = (
    <div className="AdminForm">
      {mode === 'edit' ? <button onClick={switchToNew}>Switch to new charger</button> : null}
      <table>
        <tbody>
          <tr>
            <td><label>Id</label></td>
            <td><input ref={fId} type="number" disabled={mode === 'new'} /></td>
          </tr>
          <tr>
            <td><label>Name</label></td>
            <td><input ref={fName} /></td>
          </tr>
          <tr>
            <td><label>Address</label></td>
            <td><input ref={fAddress} /></td>
          </tr>
          <tr>
            <td><label>City</label></td>
            <td><input ref={fCity} /></td>
          </tr>
          <tr>
            <td><label>Description</label></td>
            <td><input ref={fDescription} /></td>
          </tr>
          <tr>
            <td><label>Lat</label></td>
            <td><input type="number" ref={fLat} /></td>
          </tr>
          <tr>
            <td><label>Lng</label></td>
            <td><input type="number" ref={fLng} /></td>
          </tr>
          <tr>
            <td><label>Open From</label></td>
            <td><input ref={fOpenFrom} /></td>
          </tr>
          <tr>
            <td><label>Open To</label></td>
            <td><input ref={fOpenTo} /></td>
          </tr>
          <tr>
            <td><label>Phone</label></td>
            <td><input type="number" ref={fPhone} /></td>
          </tr>
          <tr>
            <td><label>Postal Code</label></td>
            <td><input ref={fPostalCode} /></td>
          </tr>
          <tr>
            <td><label>Price</label></td>
            <td><input type="number" ref={fPrice} /></td>
          </tr>
          <tr>
            <td><label>User Id</label></td>
            <td><input type="number" ref={fUserId} /></td>
          </tr>
          <tr>
            <td><label>Flag Id</label></td>
            <td><input type="number" ref={fFlagId} /></td>
          </tr>
          <tr>
            <td><label>Accepted</label></td>
            <td><input type="checkbox" ref={fAccepted} /></td>
          </tr>
          <tr>
            <td><label>Working</label></td>
            <td><input type="checkbox" ref={fWorking} /></td>
          </tr>
          <tr>
            <td></td>
            <td><button onClick={uploadCharger}>Submit</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  )

  return (
    <div className="AdminContainer">
      <div className="AdminBar">
        {searchBox}
        {query}
      </div>
      <div className="AdminTable">
        <table border='1' width='100%'>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>User</th>
              <th>Accepted</th>
              <th>Working</th>
              <th>City</th>
              <th>Address</th>
              <th>Open From</th>
              <th>Open To</th>
              <th>Destroy</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {mapping}
          </tbody>
        </table>
        {newForm}
      </div>
      <Pagination
        page={page}
        pagination={pagination}
        goToPageHandler={setPage}
      />
    </div>
  )
}

export default CarsView