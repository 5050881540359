import React, { useState } from 'react'
import {connect} from 'react-redux'
import './ReservationHeader.css'
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import { withStyles } from "@material-ui/core/styles";

const style = {
  dupa: {
    "font-size": "50px",
    position: 'absolute',
    right: '20px',
    top: '2px'
  }
}

const ReservationHeader = props => {

  const [show, setShow] = useState(false)

  const clickHandler = () => {
    setShow(!show)
  }

  let hidden = null
  if (props.reservation) {
    hidden = (
      <div className="ReservationHidden" onClick={clickHandler}><ErrorOutline classes={{root: props.classes.dupa}}/></div>
    )
  }

  let header = null
  if (props.reservation) {
    header = (
      <div className="ReservationHeader" onClick={clickHandler}>
        <h3>{global.translate('You have a reservation')}!</h3>
        <p>{global.translate('Rezervation')}: {props.reservation.time_left <= 0 ? global.translate('now') : props.reservation.time_left + ' ' + global.translate('minutes')}</p>
        <p>{global.translate('Reservation takes')}: {props.reservation.time} {global.translate('minutes')}</p>
      </div>
    )
  }
  
  return show ? header : hidden
}

const STP = (state) => ({
  reservation: state.reservation
});

export default withStyles(style)(connect(STP, null)(ReservationHeader))