import React, { useState, useEffect } from 'react'
import "./Admin.css"
import Api from '../../HOC/Api'
import Pagination from './Pagination'
import Swal from "sweetalert2";

const formId = React.createRef()
const formLogin = React.createRef()
const formEmail = React.createRef()
const formStatus = React.createRef()
const formAvatar = React.createRef()
const formResetPassword = React.createRef()

const UsersView = props => {

  const [users, setUsers] = useState([])
  const [page, setPage] = useState(1)
  const [pagination, setPagination] = useState({})
  const [orderBy, setOrderBy] = useState('id')
  const [order, setOrder] = useState('asc')
  const [search, setSearch] = useState('')

  const getUsers = async () => {
    const url = `/admin/users?page=${page}&order_by=${orderBy}&order=${order}&search=${search}`
    const result = await Api.get(url)
    setUsers(result.data.users)
    setPage(result.data.pagination.page)
    setPagination({
      total: result.data.pagination.count,
      pages: result.data.pagination.pages,
      items: result.data.pagination.items
    })
  }

  const updateUser = async () => {
    const url = `/admin/users/${formId.current.value}`
    const params = {
      user: {
        nick: formLogin.current.value,
        email: formEmail.current.value,
        status: formStatus.current.value
      },
      delete_avatar: formAvatar.current.checked,
      delete_reset: formResetPassword.current.checked
    }
    const response = await Api.patch(url, params)
    if (response.code === 200) {
      alert('OK')
    } else {
      alert('Error')
    }
    getUsers()
  }

  const setUser = (id, login, email) => {
    formId.current.value = id
    formLogin.current.value = login
    formEmail.current.value = email
  }

  const destroyUser = async id => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.value) {
        const url = `/admin/users/${id}`
        const response = await Api.delete(url)
        if (response.code === 400) {
          Swal.fire({
            type: 'error',
            title: 'Error'
          })
        }
        getUsers()
      }
    })
  }

  useEffect(() => {
    getUsers()
  }, [page, orderBy, order, search]) // eslint-disable-line

  const goToPageHandler = arg => {
    setPage(arg)
  }

  const mapping = users.map(user => {
    let color = 'green'
    if (user.status === 'admin') {
      color = 'red'
    } else if (user.status === 'distributor') {
      color = 'yellow'
    }
    return (
      <tr key={user.id}>
        <td>{user.id}</td>
        <td>{user.nick}</td>
        <td>{user.email}</td>
        <td style={{color: color}}>{user.status}</td>
        <td>{user.reset_password ? 'true' : 'false'}</td>
        <td>{user.get_created_at}</td>
        <td>{user.get_updated_at}</td>
        <td>{user.avatar ? <a href={user.avatar} target="_blank" rel="noopener noreferrer">Link</a> : '-'}</td>
        <td><span style={{cursor: 'pointer', color: 'red'}} onClick={() => destroyUser(user.id)}>Destroy</span></td>
        <td><span style={{cursor: 'pointer', color: 'green'}} onClick={() => setUser(user.id, user.nick, user.email)}>Edit</span></td>
      </tr>
    )
  })

  const searchBox = (
    <div className="AdminSearch">
      <input placeholder="Search" value={search} onChange={event => setSearch(event.target.value)}/>
    </div>
  )

  const query = (
    <div className="AdminQuery">
      <select onChange={event => setOrderBy(event.target.value)} value={orderBy}>
        <option value='id'>ID</option>
        <option value='nick'>Login</option>
        <option value='email'>Email</option>
        <option value='status'>Status</option>
        <option value='created_at'>Registered</option>
        <option value='updated_at'>Updated</option>
        <option value='avatar'>Avatar</option>
      </select>
      <select onChange={event => setOrder(event.target.value)} value={order}>
        <option value='asc'>Ascending</option>
        <option value='desc'>Descending</option>
      </select>
    </div>
  )

  const editForm = (
    <div className="AdminForm">
      <h3>Edit User</h3>
      <table>
        <tbody>
          <tr>
            <td><label>Id </label></td>
            <td><input ref={formId} placeholder="Id" type="number"/></td>
          </tr>
          <tr>
            <td><label>Login </label></td>
            <td><input ref={formLogin} placeholder="Login"/></td>
          </tr>
          <tr>
            <td><label>Email </label></td>
            <td><input ref={formEmail} placeholder="Email"/></td>
          </tr>
          <tr>
            <td><label>Status</label></td>
            <td>
              <select ref={formStatus}>
                <option>normal</option>
                <option>admin</option>
                <option>distributor</option>
              </select>
            </td>
          </tr>
          <tr>
            <td><label>Delete Avatar?</label></td>
            <td><input ref={formAvatar} type="checkbox" /></td>
          </tr>
          <tr>
            <td><label>Delete Reset Password Request?</label></td>
            <td><input ref={formResetPassword} type="checkbox" /></td>
          </tr>
          <tr>
            <td></td>
            <td><button onClick={updateUser}>Submit</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  )

  return (
    <div className="AdminContainer">
      <div className="AdminBar">
        {searchBox}
        {query}
      </div>
      <div className="AdminTable">
        <table border='1' width='100%'>
          <thead>
            <tr>
              <th>ID</th>
              <th>Login</th>
              <th>Email</th> 
              <th>Status</th>
              <th>Reset Password</th>
              <th>Registered</th>
              <th>Updated</th>
              <th>Avatar</th>
              <th>Destroy</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {mapping}
          </tbody>
        </table>
        {editForm}
      </div>
      <Pagination 
        page={page} 
        pagination={pagination}
        goToPageHandler={goToPageHandler}
      />
    </div>
  )
}

export default UsersView