import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import "../../../Style/ChargerProfile/ChargerProfileNav/ChargerProfileNav.css";

import Apps from "../../../assets/images/icon1.svg";
import PhotoLibrary from "../../../assets/images/icon3.svg";
import EventNote from "../../../assets/images/icon2.svg";
import SupervisedUserCircle from "../../../assets/images/icon4.svg";
import TextSms from "../../../assets/images/icon5.svg";
import Apps1 from "../../../assets/images/icon1-active.svg";
import PhotoLibrary1 from "../../../assets/images/icon3-active.svg";
import EventNote1 from "../../../assets/images/icon2-active.svg";
import SupervisedUserCircle1 from "../../../assets/images/icon4-active.svg";
import TextSms1 from "../../../assets/images/icon5-active.svg";

const ChargerProfileNav = props => {
  const [link, setLink] = useState('charger')
  

  return (
    <div>
      <div className="ProfileNavContent">
        <div className="ProfileNavBox">
          <NavLink to={`/charger/${props.chargerId}`} exact>
            {link==='charger'? <img alt='img' src={Apps1} /> : <img alt='img' src={Apps} onClick={() => setLink(link => 'charger')}/>}
          </NavLink>
        </div>
        <div className="ProfileNavBox">
          <NavLink to={`/charger/${props.chargerId}/reservation`}>
            {link==='reservation' ? <img alt='img' src={EventNote1} /> : <img alt='img' src={EventNote} onClick={() => setLink(link => 'reservation')} />}
          </NavLink>
        </div>
        <div className="ProfileNavBox">
          <NavLink to={`/charger/${props.chargerId}/fotos`}>
            {link==='images' ? <img alt='img' src={PhotoLibrary1}/> : <img alt='img' src={PhotoLibrary} onClick={() => setLink(link => 'images')} />}
          </NavLink>
        </div>
        <div className="ProfileNavBox">
          <NavLink to={`/charger/${props.chargerId}/opinion`}>
            {link==='users' ? <img alt='img' src={SupervisedUserCircle1}/> : <img alt='img' src={SupervisedUserCircle} onClick={() => setLink(link => 'users')}/>}
          </NavLink>
        </div>
        <div className="ProfileNavBox">
          <NavLink to={`/charger/${props.chargerId}/comments`}>
            {link ==='coments' ? <img alt='img' src={TextSms1}/> : <img alt='img' src={TextSms} onClick={() => setLink(link => 'coments')}/>}
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default ChargerProfileNav;
