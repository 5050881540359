import React, {useState} from "react";
import { connect } from 'react-redux';
import {Link, withRouter} from 'react-router-dom'
import WindowSizeListener from 'react-window-size-listener'

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import logo from "../../assets/images/logo.png";

import "../../Style/Navbar/Navbar.css";
import { Typography } from "@material-ui/core";

const Navbar = props => {
  const [width, setWidth] = useState(window.innerWidth)

  const burger = (
    <div className="MenuToggle" onClick={props.clicked}>
      <input type="checkbox" ref={props.checkboxRef} />
		  {
			  props.newMessages === false ? null :
			  <div className="NewMessagesIcon">!</div>
		  }
      <span></span>
      <span></span>
      <span></span>
    </div>
  )

 const getTitle = () => {
   const { location } = props;
   const locationRoot = location.pathname.split('/')[1];
   let title = ""
   switch(locationRoot) {
     case 'messages':
      title = "Your messages";
      break;
      case 'cars':
        title = "Your cars";
        break;
      case 'charger':
        title = "Terminal profile";
        break;
      case 'stats':
        title = "Your statistics"
        break;
      case 'nav':
        title = "Find route"
        break;
      case 'find_charger':
        title = "Find terminal"
        break;
      case 'report_charger':
        title = "Report terminal"
        break;
      case 'find_user':
        title = "Find person"
        break;
      case 'user_reservation':
        title = "Your reservations"
        break;
      case 'profile':
        title = "Profile"
        break;
      default: {
        title = "Chargelity"
        break;
    }
  }
  return <Typography variant="h6" style={{ padding: "0 16px"}}>{global.translate(title)}</Typography>
 }

  const desktop = width >= 1200

  return (
    <AppBar
      position="static"
      style={{ backgroundColor: "#707070", fontSize: "24px" }}
    >
      <WindowSizeListener onResize={size => setWidth(size.windowWidth)} />
      <Toolbar>
        {desktop ? null : burger}
        {desktop ? null : getTitle()}
        <Link to="/home"><img src={logo} className="Logo" alt="ee" onClick={props.closeHandler} /></Link>
      </Toolbar>
    </AppBar>
  );
};

const STP = (state) => ({
	newMessages: state.newMessages
})

export default  connect(STP, null)(withRouter(Navbar));
