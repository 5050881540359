import React,{ useState, useEffect } from "react";
import Api from '../../HOC/Api';
import defaultAvatar from '../../assets/images/avatar.svg';
import List from "@material-ui/core/List";

//import SettingsIcon from '@material-ui/icons/SettingsRounded';
import SetRoute from "../../assets/images/trasa.svg"
import Messages from '../../assets/images/wiadomosci.svg'
import LogOut from '../../assets/images/wyloguj.svg'
import Search from '../../assets/images/wyszukaj.svg'
import Statistick from '../../assets/images/statystyki.svg'
import Reservation from '../../assets/images/rezerwacje.svg'
import FindPerson from '../../assets/images/znajdzosobe.svg'
import Report from '../../assets/images/zglos.svg'
import Cars from '../../assets/images/samochody.svg'
import Start from '../../assets/images/start.svg'
import WindowSizeListener from 'react-window-size-listener'

import { connect } from "react-redux";

import SidebarOption from "./SidebarOption";
import "../../Style/Sidebar/Sidebar.css";

const Sidebar = props => {
  const [avatar, setAvatar] = useState('');
  const [width, setWidth] = useState(window.innerWidth);


  const getInfo = async () => {
    const result = await Api.get('/user');

    if (result.code === 200) {
      if (result.data.user.avatar) {
        setAvatar(result.data.user.avatar);
      } else {
        setAvatar(defaultAvatar)
      }
    }
  };

  useEffect(() => {
    getInfo();
  }, []);

  useEffect(() => console.log(width), [width])

  const desktop = width >= 1200
  const cssClasses = ["Sidebar", props.show || desktop ? "SidebarOpen" : "SidebarClose"];
  const cssClasses2 = ["SideDraw", props.show && !desktop ? "SideDrawOpen" : "SideDrawClose"];
  const background = <div onClick={props.onClose} className="SidebarBackground"/>
  return (
    <div>
      <WindowSizeListener onResize={size => setWidth(size.windowWidth)} />
      {props.show && !desktop ? background : null}
      <div className={cssClasses2.join(" ")} ></div>
    <div className={cssClasses.join(" ")}>
      <List>
        <div className="UserProfile">
        {props.currentUser == null ? null : (
          <SidebarOption
          AvatarGame = "AvatarGame"
            className="AvatarUser"
            path="/profile"
            label={props.currentUser.nick}
            icon={avatar}
            onClick={props.onClose}
          />
        )}
        </div>
        <SidebarOption
          path="/"
          label={global.translate('Start')}
          icon={Start}
          onClick={props.onClose}
        />
        { props.currentUser ? (
          props.currentUser.status !== 'admin' ? null : (
          <SidebarOption
            path="/admin"
            label={global.translate('Admin panel')}
            icon={avatar}
            onClick={props.onClose}
          />
        )) : null}
        <SidebarOption
          path="/cars"
          label={global.translate('Your cars')}
          icon={Cars}
          onClick={props.onClose}
        />

        <SidebarOption
          path="/nav"
          label={global.translate('Find route')}
          icon={SetRoute}
          onClick={props.onClose}
        />
        <SidebarOption
          path="/messages"
          label={global.translate('Your messages')}
          icon={Messages}
		      onClick={props.onClose}
		      redIcon={props.newMessages}
        />
        <SidebarOption
          path="/find_user"
          label={global.translate('Find person')}
          icon={FindPerson}
          onClick={props.onClose}
        />
        <SidebarOption
          path="/map_picker"
          label={global.translate('Report terminal')}
          icon={Report}
          onClick={props.onClose}
        />
        <SidebarOption
          path="/find_charger"
          label={global.translate('Find terminal')}
          icon={Search}
          onClick={props.onClose}
        />
        <SidebarOption
          path="/stats"
          label={global.translate('Your statistics')}
          icon={Statistick}
          onClick={props.onClose}
        />
        <SidebarOption
          path="/user_reservation"
          label={global.translate('Your reservations')}
          icon={Reservation}
          onClick={props.onClose}
        />
        <SidebarOption
          path="/logout"
          label={global.translate('Logout')}
          icon={LogOut}
          onClick={props.onClose}
        />
      </List>
    </div>
    </div>
  );
};

const STP = state => ({
  currentUser: state.currentUser,
  newMessages: state.newMessages
});

export default connect(
  STP,
  null
)(Sidebar);
