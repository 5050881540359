import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Progress from '@material-ui/core/CircularProgress';
import Swal from 'sweetalert2';
import Logo from '../../assets/images/logo.png';
import Api from '../../HOC/Api';
import Actions from '../../HOC/store/actions';
import Permission from '../../HOC/permission';
import '../../Style/ResetPassword/ResetPassword.css';

class ResetPasswordForm extends Component {
  constructor(props) {
    super(props);

    this.passwordInput = React.createRef();
    this.password2Input = React.createRef();
  }

  resetPassword = async () => {
    const password = this.passwordInput.current.value;
    const password2 = this.password2Input.current.value;

    if (password === '' || password2 === '') {
      this.props.showNotification('Podaj nowe hasło do konta');
      return;
    }

    if (password !== password2) {
      this.props.showNotification('Podane hasła są różne');
      return;
    }

    this.setState({ loading: true });

    const result = await Api.post('/reset_password', {
      user_id: this.state.user,
      token: this.state.token,
      password,
      password_confirmation: password2,
    });

    this.setState({ loading: false });

    console.log(result);

    if (result.code === 200) {
      Swal.fire(
        'Sukces',
        'Hasło zostało zresetowane pomyślnie. Zaloguj się używając nowego hasła.',
        'success',
      ).then(() => this.props.history.push('/login'));
    } else if (result.code === 422) {
      this.props.showNotification('Wprowadzone hasło jest za krótkie');
    } else {
      this.props.showNotification('Błąd połączenia z serwerem');
    }
  };

  state = {
    loading: false,
    form: false,
    user: null,
    token: null,
  };

  keyDownHandler = (event) => {
    if (event.keyCode === 13) this.resetPassword();
  };

  render() {
    return (
      <div className="ResetPasswordWindow">
        <Permission userDeny />
        <div>
          {!this.state.loading ? null : (
            <Progress
              className="ResetPasswordLoading"
              classes={{ root: this.props.classes.progress }}
            />
          )}
          <div className="ResetPasswordWindowContent">
            <div className="ResetPasswordLogo">
              <img alt="Logo" src={Logo} />
            </div>
            <div className="ResetPasswordHeader">
              CHARGELITY
              <br />
              Resetowanie hasła
            </div>
            {!this.state.form ? null : (
              <div className="ResetPasswordForm">
                <input
                  ref={this.passwordInput}
                  placeholder="Hasło"
                  onKeyDown={this.keyDownHandler}
                  type="password"
                />
                <input
                  ref={this.password2Input}
                  placeholder="Powtórz hasło"
                  onKeyDown={this.keyDownHandler}
                  type="password"
                />

                <button onClick={this.resetPassword}>Zapisz</button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  componentDidMount = async () => {
    const params = new URL(`http://localhost/x${this.props.history.location.search}`).searchParams;

    const user = params.get('user');
    const token = params.get('token');

    if (!user || !token) {
      Swal.fire(
        'Zły link resetowania hasła',
        'Uruchomiony link resetowania hasła jest niepoprawny lub nieaktualny. Wyślij ponownie email resetujący.',
        'error',
      ).then(() => this.props.history.push('/reset_password'));
      return;
    }

    this.setState({
      loading: true,
      user,
      token,
    });

    const result = await Api.get(
      `/reset_password/check?user=${encodeURIComponent(user)}&token=${encodeURIComponent(token)}`,
    );

    this.setState({ loading: false });

    if (result.code === 500) this.setState({ form: true });
    else if (result.code === 401) {
      Swal.fire(
        'Zły link resetowania hasła',
        'Uruchomiony link resetowania hasła jest niepoprawny lub nieaktualny. Wyślij ponownie email resetujący.',
        'error',
      ).then(() => this.props.history.push('/reset_password'));
    } else {
      Swal.fire({
        title: 'Błąd połączenia z serwerem',
        text: 'Sprawdź połączenie internetowe. Jeśli jest poprawne spróbuj ponownie później',
        type: 'error',
        confirmButtonText: 'Odśwież',
      }).then(() => global.location.reload());
    }

    console.log(result);
  };
}

const DTP = dispatch => ({
  showNotification: text => dispatch({ type: Actions.SHOW_NOTIFICATION, text }),
});

const styles = theme => ({
  progress: {
    margin: theme.spacing(2),
  },
});

export default withStyles(styles)(
  connect(
    null,
    DTP,
  )(ResetPasswordForm),
);
