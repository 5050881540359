import React, {useState} from 'react'

const Pagination = props => {

  const [nextPage, setNextPage] = useState(1)

  const css = {
    link: {
      color: 'blue',
      cursor: 'pointer'
    },
    box: {
      position: 'absolute',
      margin: 'auto',
      top: '0',
      left: '0',
      right: '0',
      width: '600px',
      border: '1px #777 solid'
    },
    total: {
      marginLeft: "30px"
    },
    search: {
      marginRight: "30px",
      color: 'blue',
      cursor: 'pointer'
    },
    searchInput: {
      width: '30px'
    }
  }

  return (
    <div style={css.box}>
      <span style={css.search}>
        <input style={css.searchInput} type="number" value={nextPage} onChange={event => setNextPage(event.target.value)}/>
        <span onClick={() => props.goToPageHandler(nextPage)}> Go</span>
      </span>
      {props.page > 1 ? <span style={css.link} onClick={() => props.goToPageHandler(props.page - 1)}>Previous ... </span> : null}
      <span>{props.page}</span>
      <span>/</span>
      <span>{props.pagination.pages}</span>
      {props.page < props.pagination.pages ? <span style={css.link} onClick={() => props.goToPageHandler(props.page + 1)}>... Next</span> : null}
      <span style={css.total}>Total: {props.pagination.total}</span>
    </div>
  )
}

export default Pagination