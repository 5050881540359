import React, { Component } from 'react';
import { connect } from 'react-redux';
import Actions from './HOC/store/actions';
import { Switch, Route, Redirect } from 'react-router-dom';
import Map from './Components/Map/Map'
import './App.css';

import Login from './Components/Login/Login';
import Layout from './Components/Layout/Layout';
import Register from './Components/Register/Register';
import Notification from "./Components/Notification/Notification";
import ResetPassword from './Components/ResetPassword/ResetPassword'
import ResetPasswordForm from './Components/ResetPassword/ResetPasswordForm'

class App extends Component {
  render() {
    return (
      <div className="App">

  <Notification />
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/reset_password" component={ResetPassword} />
          <Route path="/reset_password_form" component={ResetPasswordForm} />
          <Route path="/map" component={Map}/>
		  <Route path="/logout" exact component={() => {
			  this.props.logout();
			  return <Redirect to='/login' />;
		  }} />
          <Route path="/" component={Layout} />
        </Switch>
      </div>
    );
  }
}

const DTP = (dispatch) =>
({
	logout: () => dispatch({ type: Actions.LOGOUT })
})

export default connect(null, DTP)(App);
