import React from 'react';

import { connect } from 'react-redux';

import { withStyles } from "@material-ui/core/styles";
import Progress from '@material-ui/core/CircularProgress';

import '../../../Style/Messages/Conversation.css';

const myMessage = (props) => (
	<div className={`ConversationMessage ${props.new ? 'new' : ''}`}>
		<img alt='avatar' className='ConversationMessageAvatar ConversationMyMessageAvatar' src={props.currentUser.avatar} />
		<div className='ConversationInfo ConversationMyInfo'>
			<div className='ConversationTime ConversationMyTime'>
				{props.time}
			</div>
			<div className='ConversationContent ConversationMyContent'>
				{props.content}
				{
					props.sending !== true ? null :
					<div className="MyMessageSending">
						<Progress classes={{ root: props.classes.progress }} />
					</div>
				}
			</div>
		</div>
	</div>
);

const otherMessage = (props) => (
	<div className={`ConversationMessage ${props.new ? 'new' : ''}`}>
		<img alt='avatar' className='ConversationMessageAvatar' src={props.avatar} />
		<div className='ConversationInfo'>
			<div className='ConversationTime'>
				{props.time}
			</div>
			<div className='ConversationContent'>
				{props.content}
			</div>
		</div>
	</div>
);

const conversationMessage = (props) => (props.isMyMessage ? myMessage : otherMessage)(props);

const STP = (state) => ({
	currentUser: state.currentUser
});

const style = (theme) => ({
	progress: {
		margin: theme.spacing(2)
	}
})

export default withStyles(style)(connect(STP, null)(conversationMessage));