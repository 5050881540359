import React, {useEffect} from "react";

import Reservations from "./Reservations";

import "../../../../Style/ChargerProfile/ChargerComponents/ChargerReservation/ChargerReservation.css";
import Calendar from '../../../../assets/images/calendar.svg'
import Plus from '../../../../assets/images/plus.svg'
import Minus from '../../../../assets/images/minus.svg'

const ChargerReservation = props => {

  const currentTime = () => {
    const date = new Date
    date.setHours(date.getHours() + 1)
    return {
      startHour: date.getHours(),
      startMinutes: date.getMinutes(),
      endHour: date.getHours() + 1,
      endMinutes: date.getMinutes(),
      str: `${date.getFullYear()}-${date.getMonth()}-${date.getDay()}`
    }
  }

  const clickHandler = event => {
    let current = props[event.target.name].current
    if (event.target.alt === 'minus' && current.value > 0) {
      current.value = parseInt(current.value) - 1
    }
    if (event.target.alt === 'plus' && current.value < parseInt(current.max)) {
      current.value = parseInt(current.value) + 1
    }
  }

  const time = currentTime()

  return (
    <div className="ReservationBox">
      <div className="ReservationErrors">
        <ul>{props.errors}</ul>
      </div>
      <div className="MakeReservation">
        <p className="TitleDataReservation">Data rezerwacji</p>
        <div className="ReservationDateInput">
        <input
          type="date"
          placeholder="Podaj datę..."
          min={global.getDateString(new Date())}
          max={global.getDateString(new Date(new Date().getTime() + 604800000))}
          ref={props.reservationDate}
          defaultValue={time.str}
        />
          <img src={Calendar} alt="calendar"/>
        </div>
        <p>Czas rezerwacji</p>
        <div className="InputReservationTimeBox">
        <div className="InputReservationTimeFrom">
          <div>
          <img alt='minus' name='reservationStartAtHour' onClick={clickHandler} src={Minus}/>
            <input type="number" defaultValue={time.startHour} max="23" ref={props.reservationStartAtHour}/>
          <img alt='plus' name='reservationStartAtHour' onClick={clickHandler} src={Plus}/>
          <p>GODZINY</p>
          </div>
          <div>
          <img alt='minus' name='reservationStartAtMinutes' onClick={clickHandler} src={Minus}/>
            <input type="number" defaultValue={time.startMinutes} max="59" ref={props.reservationStartAtMinutes} />
          <img alt='plus' name='reservationStartAtMinutes' onClick={clickHandler} src={Plus}/>
          <p>MINUTY</p>
          </div>
        </div> - 
        <div className="InputReservationTimeTo">
        <div>
        <img alt='minus' name='reservationEndAtHour' onClick={clickHandler} src={Minus}/>
          <input type="number" defaultValue={time.endHour} max="23" ref={props.reservationEndAtHour} />
        <img alt='plus' name='reservationEndAtHour' onClick={clickHandler} src={Plus}/>
        <p>GODZINY</p>
        </div>
        <div>
        <img alt='minus' name='reservationEndAtMinutes' onClick={clickHandler} src={Minus}/>
          <input type="number" defaultValue={time.endMinutes} max="59" ref={props.reservationEndAtMinutes} />
        <img alt='plus' name='reservationEndAtMinutes' onClick={clickHandler} src={Plus}/>
        <p>MINUTY</p>
        </div>
        </div>
        </div>
      </div>
      <button className='ReservationButton' onClick={props.reservedClicked}>ZAREZERWUJ</button>
      {(props.charger.reservations || []).length === 0 ? (
        <div className="ChargerNoReservations">
          <i>Brak rezerwacji</i>
        </div>
      ) : (
      <Reservations
        charger={props.charger}
        cancelClicked={props.cancelClicked}
      />)}
    </div>
  );
};

export default ChargerReservation;
