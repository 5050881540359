import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import Progress from '@material-ui/core/CircularProgress';
import '../../Style/Profile/Profile.css';
import Swal from 'sweetalert2';
import Api from '../../HOC/Api';
import Actions from '../../HOC/store/actions';
import defaultAvatar from '../../assets/images/avatar.svg';
import TextField from '@material-ui/core/TextField';

const fileRef = React.createRef();

const Profile = (props) => {
  const [login, setLogin] = useState('');
  const [email, setEmail] = useState('');
  const [avatar, setAvatar] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [avaratLoading, setAvatarLoading] = useState(false);

  const getInfo = async () => {
    const result = await Api.get('/user');

    if (result.code === 200) {
      setLogin(result.data.user.nick);
      setEmail(result.data.user.email);
      setAvatar(result.data.user.avatar);
    }
  };

  useEffect(() => {
    getInfo();
  }, []);

  const submitHandler = async () => {
    const params = {
      user: {
        email,
        nick: login,
        avatar,
      },
      old_password: oldPassword,
    };
    const response = await Api.patch('/user', params);

    if (response.code === 201) {
      Swal.fire('Success!', 'Profile updated succesfully!', 'success');
    } else if (response.code === 401) {
      Swal.fire('Error!', 'Invalid password!', 'error');
    } else {
      Swal.fire('Error!', String(response.data.errors).replace(/,/g, '<br>'), 'error');
    }
  };

  const submitPassword = async () => {
    const params = {
      user: {
        password,
        password_confirmation: passwordConfirmation,
      },
      old_password: oldPassword,
    };
    const response = await Api.patch('/user', params);

    if (response.code === 201) {
      Swal.fire('Success!', 'Password updated succesfully!', 'success');
    } else {
      Swal.fire('Error!', String(response.data.errors).replace(/,/g, '<br>'), 'error');
    }
  };

  const uploadHandler = async () => {
    if (fileRef.current.files.length === 0) return;

    setAvatarLoading(true);

    const data = new FormData();
    data.append('user[avatar]', fileRef.current.files[0]);
    const result = await Api.post('/user/upload_photo', data);

    setAvatarLoading(false);

    if (result.code === 200) {
      Swal.fire({
        type: 'success',
        title: 'Sukces',
        text: 'Awatar został ustawiony pomyślnie',
        showConfirmButton: false,
        timer: 1500,
      });
      getInfo();
      props.changeAvatar(avatar);
    } else {
      Swal.fire({
        type: 'error',
        title: 'Avatar uploaded',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const cheat_browser = (
    <span>
      <input style={{ display: 'none' }} />
      <input type="password" style={{ display: 'none' }} />
    </span>
  );

  return (
    <div className="Profile MainWithGradient">
      {cheat_browser}
      <div className="Profile-box">
        <input
          onChange={uploadHandler}
          type="file"
          ref={fileRef}
          accept="image/*"
          style={{ display: 'none' }}
        />
        <div className="ProfileAvatar">
          <div
            className="ProfileAvatarImage"
            style={{ backgroundImage: `url(${avatar || defaultAvatar}` }}
            onClick={() => fileRef.current.click()}
          >
            <div className="ProfileAvatarChange">{global.translate('Change avatar')}</div>
          </div>
          {!avaratLoading ? null : (
            <Progress className="ProfileAvatarLoading" classes={{ root: props.classes.progress }} />
          )}
        </div>
      </div>
      <div className="ProfileForm">
        <h2>{global.translate('Premium account')}</h2>
        <button onClick={() => props.history.push('/payment_plans')} className="PaymentButton">{global.translate('Activate')}</button>
      </div>
      <div className="ProfileForm">
        <h2>{global.translate('Change account details')}</h2>
        <div className="Profile-box">
          <TextField
            value={oldPassword}
            type="password"
            label={global.translate('Password')}
            onChange={event => setOldPassword(event.target.value)}
          />
        </div>
        <div className="Profile-box">
          <TextField
            label={global.translate('Login')}
            type="text"
            value={login}
            onChange={event => setLogin(event.target.value)}
          />
        </div>
        <div className="Profile-box">
          <TextField
            label="Email"
            type="text"
            value={email}
            onChange={event => setEmail(event.target.value)}
          />
        </div>
        <button onClick={() => submitHandler()}>{global.translate('Submit')}</button>
      </div>
      <div className="ProfileForm">
        <h2>{global.translate('Change password')}</h2>
        <div className="Profile-box">
          <TextField
            type="password"
            label={global.translate('Current password')}
            value={oldPassword}
            onChange={event => setOldPassword(event.target.value)}
          />
        </div>
        <div className="Profile-box">
          <TextField
            type="password"
            label={global.translate('New password')}
            value={password}
            onChange={event => setPassword(event.target.value)}
          />
        </div>
        <div className="Profile-box">
          <TextField
            value={passwordConfirmation}
            type="password"
            label={global.translate('Confirm new password')}
            onChange={event => setPasswordConfirmation(event.target.value)}
          />
        </div>
        <button onClick={() => submitPassword()}>{global.translate('Change password')}</button>
      </div>
    </div>
  );
};

const dtp = dispatch => ({
  changeAvatar: avatar => dispatch({ type: Actions.CHANGE_AVATAR, avatar }),
});

const styles = theme => ({
  progress: {
    margin: theme.spacing(2),
  },
});

export default withRouter(withStyles(styles)(
  connect(
    null,
    dtp,
  )(Profile),
));
