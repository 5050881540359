import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';

class Permission extends Component
{
	render() {
		if(this.props.currentUser == null && (this.props.userRequired === true || this.props.adminRequired === true || this.props.distributorRequired))
			return <Redirect to='/login' />

		if(this.props.currentUser != null && this.props.userDeny === true)
			return <Redirect to='/home' />

		if(this.props.adminRequired === true && this.props.currentUser.status !== 'admin')
			return <Redirect to='/login' />

		if(this.props.distributorRequired === true && this.props.currentUser.status !== 'distributor')
			return <Redirect to='/login' />

		return null;
	}
}

const STP = (state) => ({
	currentUser: state.currentUser
})

export default connect(STP, null)(Permission);