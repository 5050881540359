import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import Swal from 'sweetalert2';
import SuperCluster from 'points-cluster';
import Marker from './Marker';
import MyPositionMarker from './MyPositionMarker';
import Cluster from './Cluster';
import './Map.css';
import '../MapPicker/MapPicker'
import MapPicker from '../MapPicker/MapPicker';
import Api from '../../HOC/Api';
import LocationIcon from '@material-ui/icons/MyLocation';
import MoveToLocationIcon from '@material-ui/icons/CenterFocusStrongRounded';
import Progress from '@material-ui/core/CircularProgress';
import Actions from '../../HOC/store/actions';

export let MapObject = null;

let locationInterval;


class Map extends Component
{
	constructor(props)
	{
		super(props);

		this.mapRef = MapObject = React.createRef();
	}

	state = {
		position: {},
		clusters: [],
		chargers: [],
		markers: [],
		myPosition: null,
		tracking: false,
    moveToTracking: false,
    loading: false
  }
  
  loadingScreen = (
    <div className="MapLoadingScreen">
      <Progress className="MapSpinner"/>
    </div>
	)


	render = () => (
		<div className={`Map ${this.state.tracking ? 'WithTracking' : ''} ${this.state.moveToTracking ? 'WithMoving' : ''}`}>
      		<MapPicker position={this.state.position.center} chargers={this.props.chargers}/>
			<GoogleMapReact
				ref={this.mapRef}
				bootstrapURLKeys={{ key: 'AIzaSyCJmNW2eLobRkq3l0V2wwgK-zFbsJgsVXI' }}
				defaultZoom={14}
				defaultCenter={{ lat: 53.76, lng: 20.52 }}
				onChange={this.onChange}
				onDrag={() => { if(this.state.moveToTracking) this.setState({ moveToTracking: false })}}
				yesIWantToUseGoogleMapApiInternals
				onGoogleApiLoaded={({ map, maps }) => {

					global.maps = maps;
					global.map = map;

					const div = document.createElement('div');
					div.classList.add('MyLocationButton');
					div.addEventListener('click', this.trackingToggle);
					ReactDOM.render( <LocationIcon />, div);
					map.controls[maps.ControlPosition.LEFT_TOP].push(div);

					const div2 = document.createElement('div');
					div2.classList.add('MoveToMyLocationButton');
					div2.addEventListener('click', this.moveToTrackingToggle);
					ReactDOM.render( <MoveToLocationIcon />, div2);
					map.controls[maps.ControlPosition.LEFT_TOP].push(div2);
				}}
				options={{
					minZoom: 6,
					keyboardShortcuts: false,
					gestureHandling: 'greedy',
					clickableIcons: false,
				}}
			>
				{
					this.state.clusters.map((item) => { 
					return (
						item.numPoints === 1 ?
						<Marker key={item.chargerId} lat={item.lat} lng={item.lng} id={item.chargerId} comments={item.comments}/> :
						<Cluster onClick={this.clusterClick} key={item.chargerId} lat={item.lat} lng={item.lng} count={item.text} />
					)})
				}
				{
					!this.state.myPosition ? null :
					<MyPositionMarker {...this.state.myPosition} />
				}
			</GoogleMapReact>
		</div>
	)

	trackingOff = () => {
		clearInterval(locationInterval);
		locationInterval = null;
		this.setState({ myPosition: null, tracking: false, moveToTracking: false });
	}

	locationGetter = () => {
		navigator.permissions.query({ name: 'geolocation' })
			.then((permission) => {
				switch (permission.state) {
					case 'prompt':
						navigator.geolocation.getCurrentPosition(() => { }, () => {
							this.trackingOff();
							Swal.fire('Brak uprawnień', 'Aby włączyć lokalizację, należy zezwolić tej aplikacji na pobieranie aktualnej pozycji', 'error');
						});
						break;

					case 'granted':
						navigator.geolocation.getCurrentPosition((position) => {
							const currentPos = {
								lat: position.coords.latitude,
								lng: position.coords.longitude
							}

							this.setState({ myPosition: currentPos });
							if(this.state.moveToTracking)
								MapObject.current.map_.panTo(currentPos);
						});
						break;

					case 'denied':
						this.trackingOff();
						Swal.fire('Brak uprawnień', 'Aby włączyć lokalizację, należy zezwolić tej aplikacji na pobieranie aktualnej pozycji', 'error');
						return;

					default:
						return;
				}
			})
	}

	trackingToggle = () => {
		if (this.state.tracking) {
			this.trackingOff();
			return;
		}

		navigator.permissions.query({ name: 'geolocation' })
			.then((permission) => {
				switch (permission.state) {
					case 'prompt':
						navigator.geolocation.getCurrentPosition(() => {
							this.locationGetter();
							locationInterval = setInterval(this.locationGetter, 5000);
							this.setState({ tracking: true });
						}, () => {
							this.trackingOff();
							Swal.fire('Brak uprawnień', 'Aby włączyć lokalizację, należy zezwolić tej aplikacji na pobieranie aktualnej pozycji', 'error');
						});
						break;

					case 'granted':
						this.setState({ tracking: true });
						this.locationGetter();
						locationInterval = setInterval(this.locationGetter, 5000);
						break;

					case 'denied':
						Swal.fire('Brak uprawnień', 'Aby włączyć lokalizację, należy zezwolić tej aplikacji na pobieranie aktualnej pozycji', 'error');
						return;

					default:
						return;
				}
			});
	}

	moveToTrackingToggle = () => {
		const currentState = this.state.moveToTracking;

		if(currentState){
			this.setState({ moveToTracking: false });
		} else {
			if(!this.state.tracking)
				this.trackingToggle();

			this.locationGetter();

			this.setState({ moveToTracking: true });
		}
	}

	clusterClick = (lat, lng) => {
		this.mapRef.current.map_.panTo({lat: lat, lng: lng});
		this.mapRef.current.map_.setZoom(this.mapRef.current.map_.zoom + 1);
	}

	onChange = (mapPosition) => 
	{
		if(mapPosition.center.lng > 180)
		{
			MapObject.current.map_.setCenter({ lat: mapPosition.center.lat, lng: mapPosition.center.lng - 360});
			return;
		}

		if(mapPosition.center.lng < -180)
		{
			MapObject.current.map_.setCenter({ lat: mapPosition.center.lat, lng: mapPosition.center.lng + 360});
			return;
		}

		this.setState({ position: mapPosition });
	}

	getCluster = (bounds) => {
		const chargers = this.props.chargers.filter((item) => 
			item.lat >= bounds.sw.lat && 
			item.lat <= bounds.ne.lat && 
			item.lng >= bounds.sw.lng && 
			item.lng <= bounds.ne.lng
			)
			.sort((item1, item2) => item1.lat < item2.lat ? 1 : -1);

		return SuperCluster(chargers, {
			minZoom: 6,
			maxZoom: 15,
			radius: 80
		});
	}

	componentDidMount = async () => {
		const chargersResult = await Api.get('/get_all_chargers');
		//console.log(chargersResult.data.user[0])
		let markers = []
		for(let key in chargersResult.data.user){
			const id = key
			const idMar = chargersResult.data.user[key].id
			const bool = chargersResult.data.user[key].comments
			markers.push({id,idMar, bool})
		}
		this.setState({markers: markers})
		console.log(markers)
		if(chargersResult.code === 200)
		{
			let chargers = chargersResult.data.user;

			if(this.props.currentUser)
				if(this.props.currentUser.state !== 'normal')
					chargers = chargers.concat(chargersResult.data.admin)

      this.props.setChargers(chargers);
      this.setState({loading: false})
		}
	}

	componentDidUpdate = (prevProps, oldState) => {
		if(this.state.position !== oldState.position || this.props.chargers !== prevProps.chargers){
			this.setState({ 
				clusters: this.state.position.bounds ?
					this.getCluster(this.state.position.bounds)(this.state.position)
						.map((item) => ({
							lat: item.wy,
							lng: item.wx,
							text: item.numPoints,
							numPoints: item.numPoints,
							chargerId: item.points[0].id,
							comments: item.points[0].comments
						})) : []
			})
		}
	}
}

const STP = (state) =>
({
	currentUser: state.currentUser,
	chargers: state.chargers
})

const DTP = dispatch => ({
	setChargers: (chargers) => dispatch({ type: Actions.SET_CHARGERS, chargers })
})

export default connect(STP, DTP)(Map);