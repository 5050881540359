import React from "react";

import "../../../Style/ReportCharger/ReportCharger.css";

const reportChargerInput = props => {
  return (
    <div className="ReportChargerInput">
      <div>{props.name}</div>
      <input
        type={props.type}
        ref={props.inputRef}
        disabled={props.disabled}
      />
    </div>
  );
};

export default reportChargerInput;
