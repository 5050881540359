import React, { Component } from "react";
import MyPositionMarker from '../../assets/images/my_marker.png';

class Marker extends Component
{
	state = {
		display: false,
		loading: false,
		name: '',
		address: ''
	}

	static closeOpenedInfo = null;
	static willOpenedId = null;
	static openHandlers = {};

	render = () => (
		<div className='MyPositionMarker'>
			<img onClick={this.markerOpen} alt='MyPositionMarker' src={MyPositionMarker} />
		</div>
	)
}

export default Marker;