import React, {useState, useEffect} from 'react'
import '../../Style/FindUser/FindUser.css'
import Api from '../../HOC/Api'
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import defaultAvatar from '../../assets/images/avatar.svg';
import {withRouter} from 'react-router-dom'
import Message from '../../assets/images/message.svg'
import {connect} from 'react-redux'

const FindUser = props => {
  const [cars, setCars] = useState([])
  const [users, setUsers] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [search, setSearch] = useState('login')

  const getCars = async str => {
    const response = await Api.get(`/cars?plate=${str.substring(0,2)}-${str.substring(2,4)}`)
    console.log(response.data)
    if (response.code === 200) {
      setCars(response.data.cars)
    } else {
      alert('Error')
    }
  }

  const getUsers = async str => {
    const response = await Api.get(`/users?string=${str}`)
    if (response.code === 200) {
      setUsers(response.data.users)
    } else {
      alert('Error')
    }
  }

  useEffect(() => {
    if (searchValue.length === 0) {
      setUsers([])
      setCars([])
      return
    }
    if (search === 'plate')
      getCars(searchValue.substring(0,4))
    if (search === 'login')
      getUsers(searchValue)
  }, [searchValue]) // eslint-disable-line

  const goToConversation = async (userId) => {
    const response = await Api.post('/conversations', {
      conversation: {
        receiver_id: parseInt(userId, 10)
      }
    })
    console.log(response.data)
    if (response.code === 200 || response.code === 201) {
      props.history.push(`/messages/${response.data.id}`);
    }
  }

  const mappingUsers = users.map(item => (
    <div key={item.id} className="FUUser">
      <div className="FUAvatar">
        <img src={item.avatar || defaultAvatar} alt="No avatar"/>
      </div>
      <div className="FULogin">{item.nick}</div>
      {props.currentUser.id !== item.id ? <img src={Message} className='fu-message' alt='message' onClick={() => goToConversation(item.id)} /> : null}
    </div>
  )) 

  const mappingCars = cars.map(item => { 
    const color = `linear-gradient(90deg, ${item.color_value} 0px, ${item.color_value} 15px, #FFF 15px, #FFF 100%)`
    return (
    <div className='FUCar' key={item.id} style={{background: color}}>
      {item.icon ? <img src={item.icon} alt='logo' className='fu-car-logo'/> : null}
      <div className='fu-middle'>
        <div className='fu-car-model'>{item.car_model}</div>
        <div className='FUCarPlate'>
          <div className='FUBeginNumber'>{item.plate_number.substring(0, 2)}</div>
          <div className='FUDots'>...</div>
          <div className='FUEndNumber'>{item.plate_number.substring(3, 5)}</div>
        </div>
      </div>
      {props.currentUser.id !== item.user_id ? <img src={Message} className='fu-message' alt='message' onClick={() => goToConversation(item.user_id)} /> : null}
    </div>
  )})

  const changeHandler = event => {
    setSearch(event.target.value)
    setSearchValue('')
  }

  return (
    <div className="FindUser MainWithGradient">
      <h4 style={{margin: '20px'}}>{global.translate('Find person')}</h4>
      <div className='find-user-query'>
        <TextField value={searchValue} placeholder={search === 'login' ? global.translate('Login / email') : global.translate('Car plate')} onChange={e => setSearchValue(e.target.value)} />
        <Select value={search} onChange={changeHandler} displayEmpty>
          <MenuItem value='plate' selected='selected'>{global.translate('By car plate')}</MenuItem>
          <MenuItem value='login'>{global.translate('By login / email')}</MenuItem>
        </Select>
      </div>
      {search === 'plate' ? mappingCars : null}
      {search === 'login' ? mappingUsers : null}
    </div>
  )
}

const STP = state => ({
  currentUser: state.currentUser
})

export default connect(STP, null)(withRouter(FindUser))