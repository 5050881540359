import React from "react";
import AddPhoto from '@material-ui/icons/AddPhotoAlternateRounded'

import "../../../../Style/ChargerProfile/ChargerComponents/ChargerFoto/ChargerFoto.css";

const chargerFoto = props => {
  return (
    <div className="ChargerFotoBox">
      <input
        type="file"
        onChange={props.uploadPhotoHandler}
        ref={props.fileRef}
        accept="image/*"
        style={{ display: 'none' }}
      />
      {(props.charger.photos || []).length === 0 ? (
        <div className="ChargerFotoNoImages">
          <i>Brak zdjęć</i>
        </div>
      ) : (
        (props.charger.photos || []).map((item, id) => (
          <div className="ChargerFotos" key={item.id}>
            <div className="ChargerFotosImage">
              <img src={item.url} alt="cos" className="ChargerDescPhoto" />
            </div>
            <div className="ChargerFotosDesc">
              <div>
                {id + 1}/{props.charger.photos.length}
              </div>
            </div>
          </div>
        ))
      )}
      <div className="AddChargerPhoto" onClick={() => props.fileRef.current.click()} >
        <AddPhoto />
        <div className="AddChargerPhotoText">Dodaj zdjęcie</div>
      </div>
    </div>
  );
};

export default chargerFoto;
