import React, { Component } from "react";

class Cluster extends Component
{
	render = () => (
		<div onClick={() => this.props.onClick(this.props.lat, this.props.lng)} className='ChargerCluster'>
			{this.props.count}
		</div>
	)
}

export default Cluster;