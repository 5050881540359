import React, { Component } from "react";

import "../../Style/ReportCharger/ReportCharger.css";

import ReportChargerSucces from "./ReportChargerSucces/ReportChargerSucces";
import ReportChargerList from "./ReportChargerList";
import ReportChargerCords from './ReportChargerCords';
import Api from "../../HOC/Api";

class ReportCharger extends Component {
  constructor(props) {
    super(props);
    this.OpenChargerAtHour = React.createRef();
    this.OpenChargerEndAtHour = React.createRef();
    this.OpenChargerEndAtMinutes = React.createRef();
    this.OpenChargerAtMinutes = React.createRef();
    this.ChargerDescription = React.createRef();
    this.state = {
      plugs: {},
      isSuccesful: false,
      checkedPlug: [],
      isNext: false,
      lat: 0,
      lng: 0
      
    };

    this.inputList = {
      name: { type: "text", name: "Nazwa", ref: React.createRef() },
      address: { type: "text", name: "Adres", ref: React.createRef() },
      price: { type: "number", name: "Cena", ref: React.createRef() },
    };
  }


  setPlug = event => {
    const id = parseInt(event.target.value, 10)
    this.setState(prevState => {
      const newState = prevState
      if (!prevState.checkedPlug.includes(id)) {
        newState.checkedPlug.push(id)
      }
      return newState
    })
  };

  cordsChargerHandler = () => {
    this.setState({isNext: true})
  }

  reportChargerHandler = async () => {
    const values = { ...this.inputList };
    Object.keys(this.inputList).forEach(
      key => (values[key] = this.inputList[key].ref.current.value)
    );
    console.log(values);
    const OpenHour = this.OpenChargerAtHour.current.value;
    const OpenMinutes = this.OpenChargerAtMinutes.current.value;
    const CloseHour = this.OpenChargerEndAtHour.current.value;
    const CloseMinutes = this.OpenChargerEndAtMinutes.current.value;
    const Description = this.ChargerDescription.current.value;

    const Open = `${OpenHour}:${OpenMinutes}`;
    const Close = `${CloseHour}:${CloseMinutes}`;

    const postReport = await Api.post("/chargers", {
      ...values,
      open_from: Open,
      open_to: Close,
      plugs: this.state.checkedPlug,
      lat: this.state.lat,
      lng: this.state.lng,
      description: Description
    });

    if (postReport.code === 201) {
      this.sendReportHandler();
    }
    console.log(postReport);
    console.log(this.state.checkedPlug);
  };

  sendReportHandler = () => {
    this.setState({ isSuccesful: true });
  };

  cancelHandler = () => {
    this.setState({ isNext: false });
  }

  getPlugs = async () => {
    const plugs = await Api.get("/plugs");
    this.setState({ plugs: plugs.data });
    console.log(plugs.data.plugs[1].id);
    console.log(this.state.plugs);
  };

  componentDidMount() {
    const url = new URL(window.location.href)
    const lat = url.searchParams.get('lat')
    const lng = url.searchParams.get('lng')
    this.setState({lat: parseFloat(lat), lng: parseFloat(lng)})
    this.getPlugs();
  }

  render() {
    return (
      <div>
        <ReportChargerCords 
          AcceptCords={this.cordsChargerHandler} 
          next={!this.state.isNext} 
          lat={this.state.lat} 
          lng={this.state.lng} 
          plugs={this.state.plugs}
          value={this.state.value}  
          change={event => this.setPlug(event)}
          />
        <ReportChargerList
          next={this.state.isNext}
          inputList={this.inputList}
          clicked={this.reportChargerHandler}
          cancel={this.cancelHandler}
          OpenChargerHour={this.OpenChargerAtHour}
          OpenChargerMinutes={this.OpenChargerAtMinutes}
          CloseChargerHour={this.OpenChargerEndAtHour}
          CloseChargerMinutes={this.OpenChargerEndAtMinutes}
          ChargerDescription={this.ChargerDescription}
        />
      </div>
    );
  }
}

export default ReportCharger;
