import React, { useState, useEffect } from 'react'
import "./Admin.css"
import Api from '../../HOC/Api'
import Pagination from './Pagination'
import Swal from "sweetalert2";

const carName = React.createRef()
const carBrand = React.createRef()
const carId = React.createRef()

const CarsView = props => {

  const [cars, setCars] = useState([])
  const [page, setPage] = useState(1)
  const [pagination, setPagination] = useState({})
  const [orderBy, setOrderBy] = useState('id')
  const [order, setOrder] = useState('asc')
  const [search, setSearch] = useState('')

  const getCars = async () => {
    const url = `/admin/car_models?page=${page}&order_by=${orderBy}&order=${order}&search=${search}`
    const result = await Api.get(url)
    setCars(result.data.cars)
    setPage(result.data.pagination.page)
    setPagination({
      total: result.data.pagination.count,
      pages: result.data.pagination.pages,
      items: result.data.pagination.items
    })
  }

  const setCar = (id, name, brand) => {
    carId.current.value = id
    carName.current.value = name
    carBrand.current.value = brand
  }

  const uploadCar = async () => {
    const url = '/admin/car_models'
    const params = {
      name: carName.current.value,
      brand: carBrand.current.value
    }
    const response = await Api.post(url, params)
    carName.current.value = null
    carBrand.current.value = null
    if (response.code === 200) {
      alert('OK')
    } else {
      alert('Error')
    }
    getCars()
  }

  const patchCar = async () => {
    const url = `/admin/car_models/${carId.current.value}`
    const params = {
      name: carName.current.value,
      brand: carBrand.current.value
    }
    const response = await Api.patch(url, params)
    carName.current.value = null
    carBrand.current.value = null
    carId.current.value = null
    if (response.code === 200) {
      alert('OK')
    } else {
      alert('Error')
    }
    getCars()
  }

  const destroyCar = async id => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.value) {
        const url = `/admin/car_models/${id}`
        const response = await Api.delete(url)
        if (response.code === 400) {
          Swal.fire({
            type: 'error',
            title: 'Error'
          })
        }
        getCars()
      }
    })
  }

  useEffect(() => {
    getCars()
  }, [page, orderBy, order, search]) // eslint-disable-line

  const goToPageHandler = arg => {
    setPage(arg)
  }

  const mapping = cars.map(car => {
    return (
      <tr key={car.id}>
        <td>{car.id}</td>
        <td>{car.name}</td>
        <td>{car.brand}</td>
        <td>{car.amount}</td>
        <td><span style={{cursor: 'pointer', color: 'red'}} onClick={() => destroyCar(car.id)}>Destroy</span></td>
        <td><span style={{cursor: 'pointer', color: 'green'}} onClick={() => setCar(car.id, car.name, car.brand)}>Edit</span></td>
      </tr>
    )
  })

  const searchBox = (
    <div className="AdminSearch">
      <input placeholder="Search" value={search} onChange={event => setSearch(event.target.value)}/>
    </div>
  )

  const query = (
    <div className="AdminQuery">
      <select onChange={event => setOrderBy(event.target.value)} value={orderBy}>
        <option value='id'>ID</option>
        <option value='brand'>Brand</option>
        <option value='name'>Model</option>
      </select>
      <select onChange={event => setOrder(event.target.value)} value={order}>
        <option value='asc'>Ascending</option>
        <option value='desc'>Descending</option>
      </select>
    </div>
  )

  const newCarForm = (
    <div className="AdminForm">
      <h3>New Car</h3>
      <label>Name </label>
      <input ref={carName} placeholder="Name"/>
      <br />
      <label>Brand </label>
      <input ref={carBrand} placeholder="Brand"/>
      <br />
      <button onClick={uploadCar}>Submit</button>
    </div>
  )

  const editCarForm = (
    <div className="AdminForm">
      <h3>Edit Car</h3>
      <label>Name </label>
      <input ref={carId} placeholder="Id" type="number"/>
      <br />
      <label>Name </label>
      <input ref={carName} placeholder="Name"/>
      <br />
      <label>Brand </label>
      <input ref={carBrand} placeholder="Brand"/>
      <br />
      <button onClick={patchCar}>Submit</button>
    </div>
  )

  return (
    <div className="AdminContainer">
      <div className="AdminBar">
        {searchBox}
        {query}
      </div>
      <div className="AdminTable">
        <table border='1' width='100%'>
          <thead>
            <tr>
              <th>ID</th>
              <th>Model</th>
              <th>Brand</th>
              <th>Amount</th>
              <th>Destroy</th>
            </tr>
          </thead>
          <tbody>
            {mapping}
          </tbody>
        </table>
        {newCarForm}
        {editCarForm}
      </div>
      <Pagination 
        page={page} 
        pagination={pagination}
        goToPageHandler={goToPageHandler}
      />
    </div>
  )
}

export default CarsView