import React, { Component } from "react";
import { Link } from "react-router-dom";

import Search from "../../assets/images/wyszukaj.svg";

import ChargerIcon from "../../assets/images/new_marker.svg";
import ArrowBack from "@material-ui/icons/ArrowBackIosRounded";
import ArrowNext from "@material-ui/icons/ArrowForwardIosRounded";

import "../../Style/ChargerFinder/ChargerFinder.css";
import Api from "../../HOC/Api";


const easterEggRef = React.createRef()

class ChargerFinder extends Component {
  constructor(props) {
    super(props);

    this.searchInput = React.createRef();
  }

  state = {
    chargersList: [],
    page: 1,
    pages: 1,
    find: ""
  };

  get searchValue() {
    return this.searchInput.current.value.trim();
  }

  searchOnKeyDownHandler = event => {
    if (event.keyCode === 13) this.findChargers();
  };

  getChargerHandelr = async event => {

    if (this.searchValue.length >= 3) {
      const getchargers = await Api.get(
        "/chargers?search=" +
          encodeURIComponent(this.searchValue) +
          "&items=5&page=" +
          this.state.page
      );

      if (getchargers.code === 200) {
        this.setState({
          chargersList: getchargers.data.chargers,
          pages: getchargers.data.pagination.pages
        });
      }
    } else {
      this.setState({
        chargersList: []
      });
    }
  };

  findChargers = () => {
    this.setState({
      find: this.searchValue,
      page: 1
    });
  };

  changePage = page => {
    if (page < 1) page = 1;

    if (page > this.state.pages) page = this.state.pages;

    this.setState({
      page: page
    });
  };

  interval = null

  componentDidUpdate = (_, prevState) => {
    if (
      prevState.page !== this.state.page ||
      prevState.find !== this.state.find
    )
      this.getChargerHandelr();
  };

  render() {
    const { classes } = this.props;

    let searchStyle = { opacity: "0.5" };
    if (this.searchInput.current) {
      if (this.searchValue.length >= 3) {
        searchStyle = null;
      }
    }

    const changeColor = () => {
      let r = Math.floor(Math.random() * 255)
      let g = Math.floor(Math.random() * 255)
      let b = Math.floor(Math.random() * 255)
      if (easterEggRef.current) {
        easterEggRef.current.style.color = `rgb(${r}, ${g}, ${b})`
      }
    }
    let easterEgg = null
    let easterEgg2 = null
    const audio = <audio src="https://www.myinstants.com/media/sounds/bladefury_start_loopcustomrecord.mp3" autoPlay loop />
    const audio2 = <audio src="https://www.myinstants.com/media/sounds/saaaadaffleck.mp3" autoPlay/>
    clearInterval(this.interval)
    if (this.searchInput.current){
      easterEgg2 = this.searchInput.current.value.toLowerCase() === 'hello darkness'
      if (this.searchInput.current.value.toLowerCase() === 'you spin me round round') {
        this.interval = setInterval(changeColor, 50)
        easterEgg = true
      } else {
        if (easterEggRef.current) {
          easterEggRef.current.style.color = '#fff'
        }
      }
    }

    let noChargers = null
    if (this.searchInput.current)
      if (this.searchInput.current.value.length > 2)
        noChargers = 'Brak Terminali'

    return (
      <div className={easterEgg2 ? "ChargerFinderArea MainWithGradient EasterEgg2" : "ChargerFinderArea MainWithGradient"}>
        <div className="ChargerFinderContent">
          {easterEgg ? audio : null}
          {easterEgg2 ? audio2 : null}
          <div className="search_input">
            <input
              className={easterEgg ? "EasterEgg" : null} ref={easterEggRef}
              type="text"
              ref={this.searchInput}
              onKeyDown={this.searchOnKeyDownHandler}
              onChange={this.getChargerHandelr}
              placeholder={`${global.translate('Find terminal')} ...`}
            />
          </div>
          <div
            className="search"
            onClick={this.findChargers}
            style={searchStyle}
          >
            <img alt='szukaj' src={Search}/>
          </div>
        </div>
        <div className="ChargerFinderResultList">
          {this.state.chargersList.length === 0 ? (
            <div style={{ marginTop: "30px" }}>
              <i>{noChargers}</i>
            </div>
          ) : (
            <React.Fragment>
              {this.state.chargersList.map(item => (
                <Link key={item.id} to={`/charger/${item.id}`}>
                  <div key={item.id} className="ChargerFinderResult">
                    <img alt="charger" src={ChargerIcon} />
                    <div className="info">
                      {item.name}
                      <br />
                      <div className="address">
                        <img src={item.flag} alt=" " />
                        {item.city}
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
              <div className="ChargerFinderPagination">
                <div
                  className="ChargerFinderPaginationButton"
                  onClick={() => this.changePage(this.state.page - 1)}
                >
                  <ArrowBack />
                </div>
                {this.state.page}/{this.state.pages}
                <div
                  className="ChargerFinderPaginationButton"
                  onClick={() => this.changePage(this.state.page + 1)}
                >
                  <ArrowNext />
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default ChargerFinder;
