import React, { useState } from 'react'
import "./Admin.css"

import UsersView from './UsersView'
import CarsView from './CarsView'
import ReservationsView from './ReservationsView'
import ChargersView from './ChargersView'
import Permission from '../../HOC/permission'

const Admin = props => {

  const [mode, setMode] = useState('chargers')

  const authorized = (
    <div>
      <h1>Admin Panel</h1>
      <div className="AdminPanel">
        <h2>Menu</h2>
        <hr />
        <h4 onClick={() => setMode('users')}>Users</h4>
        <hr />
        <h4 onClick={() => setMode('cars')}>Cars</h4>
        <hr />
        <h4 onClick={() => setMode('reservations')}>Reservations</h4>
        <hr />
        <h4 onClick={() => setMode('chargers')}>Chargers</h4>
        <hr />
      </div>
      <div className="AdminContent">
        {mode === 'users' ? <UsersView /> : null}
        {mode === 'cars' ? <CarsView /> : null}
        {mode === 'reservations' ? <ReservationsView /> : null}
        {mode === 'chargers' ? <ChargersView /> : null}
      </div>
      <div className="AdminNotification">
        Admin panel for desktop only (min 1366px)
      </div>
    </div>
  )

  return (
    <div className="MainWithGradient">
      <Permission adminRequired />
      {authorized}
    </div>
  )
}

// const STP = (state) => ({
//   admin: state.currentUser.status
// });

// export default connect(STP)(Admin)
export default Admin