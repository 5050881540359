import Api from '../../HOC/Api'
import axios from 'axios'

let all // all chargers
let set // chargers within maxRadius
let lat
let lng
let maxRadius = 5
let iterations = 200
let maxPoints = 210
const API_KEY = 'AIzaSyCJmNW2eLobRkq3l0V2wwgK-zFbsJgsVXI'

const get_max_radius = () => {
  let output = []
  all.forEach(item => {
    const length = (lat - item.lat)*(lat - item.lat) + (lng - item.lng)*(lng - item.lng)
    if (length <= maxRadius * maxRadius) {
      output.push(item)
    }
  })
  return output
}

const radius = (min, max) => {
  let output = 0
  set.forEach(item => {
    const length = (lat - item.lat)*(lat - item.lat) + (lng - item.lng)*(lng - item.lng)
    if (length <= max*max && length >= min*min) {
      output += 1
    }
  })
  return output
}

export const isWater = (lat, lng) => new Promise(async (resolve, _) => {
  let url = `https://api.onwater.io/api/v1/results/${lat},${lng}`
  let response = {status: 400}
  try {
    response = await axios.get(url)
  }
  catch(err) {
    console.log(err)
  }
  if (response.status === 200) {
    resolve(response.data.water)
  } else {
    let result = false
    console.log('Maximum number of requests reached\nUsing google maps API')
    url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${API_KEY}`
    response = await Api.get_raw(url)
    if (response.code !== 200) {
      resolve(true)
    } else {
      response.data.results.forEach(item => {
        if (item.types.includes('natural_feature')) {
          result = true
        }
      })
      resolve(result)
    }
    resolve(true)
  }
  console.log(response.data)
  resolve(true)
})

let percentColors = [
  { pct: 0.0, color: { r: 0xff, g: 0x00, b: 0 } },
  { pct: 0.5, color: { r: 0xff, g: 0xff, b: 0 } },
  { pct: 1.0, color: { r: 0x00, g: 0xff, b: 0 } } ];

let getColorForPercentage = pct => {
  for (var i = 1; i < percentColors.length - 1; i++) {
    if (pct < percentColors[i].pct) {
        break;
    }
  }
  let lower = percentColors[i - 1];
  let upper = percentColors[i];
  let range = upper.pct - lower.pct;
  let rangePct = (pct - lower.pct) / range;
  let pctLower = 1 - rangePct;
  let pctUpper = rangePct;
  let color = {
    r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
    g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
    b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
  };
  const shade = 20
  color.r -= shade
  color.g -= shade
  color.b -= shade
  return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
}  

const calc = (x, y, chargers) => {
  all = chargers
  lat = x
  lng = y

  let step = maxRadius / iterations
  let points = maxPoints
  let amount = 0

  set = get_max_radius(lat, lng, maxRadius)
  if (set.length === 0) return [100, getColorForPercentage(1)]

  for (let i=0; i<iterations; i++) {
    amount = radius(i * step, (i + 1) * step)
    points -= (amount * step)**(1/12)
  }
  
  let result = points / maxPoints * 100
  if (result < 0)
    result = 0
  let color = getColorForPercentage(result/100)
  return [result.toFixed(2), color]
}

export const getProfitabiltyTextAndColorByPercent = (percentValue) => {
  if(percentValue > 85) {
    return ["Very high", "#00bfff"]
  } else if(percentValue > 60) {
    return ["High", "#00b300"];
  } else if(percentValue > 40) {
    return ["Medium", "#ff8000"];
  } else if(percentValue > 20) {
    return ["Low", "#cc0000"];
  } else return ["Very low", "660000"];
}

export default calc