import React from "react";

import "../../Style/Navigation/Navigation.css";
import TextField from '@material-ui/core/TextField';
import Triangle from '@material-ui/icons/DetailsRounded';

const navigationSearch = props => {
  return (
    <div
      className="NaviRouteContent"
      style={{ display: props.open ? "block" : "none" }}
    >
      <div className="NaviRoute">
        <div className="Tringle"><Triangle /></div>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className="NaviSearchRoute">
        <TextField type="text" placeholder={global.translate('Enter city')} ref={props.fromRef} />
        <br />
        <TextField type="text" placeholder={global.translate('Enter city')} ref={props.toRef} />
      </div>
    </div>
  );
};

export default navigationSearch;
