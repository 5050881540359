import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Progress from '@material-ui/core/CircularProgress';
import Logo from '../../assets/images/logo.png';
import Api from '../../HOC/Api';
import Actions from '../../HOC/store/actions';
import Permission from '../../HOC/permission';
import '../../Style/ResetPassword/ResetPassword.css';
import { TextField } from '@material-ui/core';

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.emailInput = React.createRef();
  }

  sendEmail = async () => {
    const email = this.emailInput.current.querySelector('input').value;

    if (email === '') {
      this.props.showNotification(global.translate('Enter your email to reset password'));
      return;
    }

    this.setState({ loading: true });

    const result = await Api.get(`/reset_password?email=${encodeURIComponent(email)}`);

    if (result.code === 200) {
      this.props.showNotification(global.translate('Email has been sent'));
      this.props.history.push('/login');
    } else if (result.code === 404) {
      this.props.showNotification(global.translate('Account not found'));
    } else {
      this.props.showNotification(global.translate('Server connection error'));
    }

    this.setState({ loading: false });
  };

  state = {
    loading: false,
  };

  keyDownHandler = (event) => {
    if (event.keyCode === 13) this.sendEmail();
  };

  render() {
    return (
      <div className="ResetPasswordWindow">
        <Permission userDeny />
        <div>
          {!this.state.loading ? null : (
            <Progress
              className="ResetPasswordLoading"
              classes={{ root: this.props.classes.progress }}
            />
          )}
          <div className="ResetPasswordWindowContent">
            <div className="ResetPasswordLogo">
              <img alt="Logo" src={Logo} />
            </div>
          <div className="ResetPasswordHeader">{global.translate('Chargelity')}</div>
            <div className="ResetPasswordHeader2">{global.translate('Reset password')}</div>

            <div className="ResetPasswordForm">
              <div>
                <TextField
                  ref={this.emailInput}
                  onKeyDown={this.keyDownHandler}
                  type="text"
                  label="email"
                />
              </div>
              <div className="ResetPasswordButton">
                <button onClick={this.sendEmail}>{global.translate('SEND')}</button>
              </div>

              <div className="ResetPasswordBackButton">
                <Link to="/login">{global.translate('Back to login')}</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const DTP = dispatch => ({
  showNotification: text => dispatch({ type: Actions.SHOW_NOTIFICATION, text }),
});

const styles = theme => ({
  progress: {
    margin: theme.spacing(2),
  },
});

export default withStyles(styles)(
  connect(
    null,
    DTP,
  )(ResetPassword),
);
