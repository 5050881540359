import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import Sidebar from "../Sidebar/Sidebar";
import NavBar from "../Navbar/Navbar";
import Navigation from "../Navigation/Navigation";
import ChargerProfile from "../ChargerProfile/ChargerProfile";
import Login from "../Login/Login";
import UserStats from "../UserStats/UserStats";
import ChargerFinder from "../ChargerFinder/ChargerFinder";
import FindUser from "../FindUser/FindUser";
import ReportCharger from "../ReportCharger/ReportCharger";
import Profile from "../Profile/Profile";
import Map from "../Map/Map";
import ReservationHeader from '../UserReservations/ReservationHeader'
import Payment from '../Payment/Payment'
import PaymentPlans from '../Payment/PaymentPlans'
import Messages from '../Messages/Messages'

import Permission from "../../HOC/permission";
import Cars from "../Cars/Cars";
import Admin from "../Admin/Admin";
import UserReservationsContainer from "../UserReservations/UserReservationsContainer";


class Layout extends Component {
  constructor(props) {
    super(props);
    this.navbarCheckBox = React.createRef();
  }

  state = {
    sidebarIsOpen: false
  };

  showSidebar = () => {
    this.setState(prevState => {
      this.navbarCheckBox.current.checked = !prevState.sidebarIsOpen;
      return { sidebarIsOpen: !prevState.sidebarIsOpen };
    });
  };

  closeSidebar = () => {
    this.setState({ sidebarIsOpen: false });
    if (this.navbarCheckBox.current)
      this.navbarCheckBox.current.checked = false;
  };
  render() {
    return (
      <div className="App">
        <Permission userRequired />
        <NavBar clicked={this.showSidebar} checkboxRef={this.navbarCheckBox} closeHandler={this.closeSidebar}/>
        <Sidebar show={this.state.sidebarIsOpen} onClose={this.closeSidebar} />
        <Map />
        <ReservationHeader />
        <Switch>
          <Route path="/messages" component={Messages} exact />
          <Route path="/messages/:id" component={Messages} />
          <Route path="/cars" component={Cars} exact />
          <Route path="/cars/:id" component={Cars} />
          <Route path="/charger/:id" component={ChargerProfile} />
          <Route path="/login" component={Login} />
          <Route path="/stats" component={UserStats} />
          <Route path="/nav" component={Navigation} />
          <Route path="/find_charger" component={ChargerFinder} />
          <Route path="/find_user" component={FindUser} />
          <Route path="/user_reservation" component={UserReservationsContainer} />
          <Route path="/user_reservation/expired" component={UserReservationsContainer} />
          <Route path="/report_charger" component={ReportCharger} />
          <Route path="/profile" component={Profile} />
          <Route path="/admin" component={Admin} />
          <Route path="/payment" component={Payment} />
          <Route path="/payment_plans" component={PaymentPlans}/>
          <Route
            path="/home"
            exact
            component={() => <Permission userRequired />}
          />
          <Route path="/" exact component={() => <Permission userRequired />} />
        </Switch>
        </div>
    );
  }
}

export default Layout;
