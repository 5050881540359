import React from 'react';
import { NavLink } from "react-router-dom";
import MessagesReceiver from './MessagesReceiver';
import { withStyles } from "@material-ui/core/styles";

import '../../Style/Messages/Messages.css';

import Progress from '@material-ui/core/CircularProgress';

const styles = theme => ({
	addCircle: {
		fontSize: '40px',
		display: 'inline-block',
		verticalAlign: 'middle'
	},
	progress: {
		margin: theme.spacing(2)
	}
})

const messagesReceiversList = (props) => {
	const { classes } = props
	
	return (
		<div className='MessagesReceiversList' style={{left: props.open ? '0%' : '-100%'}}>
			<NavLink to='/find_user'>
				<div className='chat-header'>{global.translate('Start conversation')}</div>
			</NavLink>
			{
				props.loading !== true ? null :
				<div className='MessagesReceiversLoading'>
					<Progress classes={{ root: classes.progress }} />
				</div>
			}
			{
				props.loading === true ? null :
				(
					props.conversations.length === 0 ? <i style={{color: '#888', marginTop: '20px'}}>{global.translate('No conversations')}</i> :
					props.conversations.map((item) => (
					<MessagesReceiver
						key={item.id}
						onOpen={() => props.onOpenConversation(item)}
						avatar={item.avatar}
						time={item.last_date}
						name={item.nick}
						new={item.unread_messages}
					/>
					))
				)
			}
		</div>
);
}

export default withStyles(styles)(messagesReceiversList);