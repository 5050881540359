import React, {useState} from 'react'
import TextField from '@material-ui/core/TextField';
import {withRouter} from 'react-router-dom'
import Link from '@material-ui/core/Link';
import Swal from 'sweetalert2';
import "../../Style/Payment/Payment.css"
import Validator from 'card-validator'


const Payment = props => {
  const [number, setNumber] = useState('')
  const [date, setDate] = useState('')
  const [cvc, setCvc] = useState('')

  const validate = () => {
    let result = true
    let errorsList = []

    if (number.length === 0) {
      errorsList.push('Numer karty kredytowej jest pusty')
      result = false
    } else if (!(number.length >= 12 && number.length <= 40)) {
      errorsList.push('Niepoprawny number karty kredytowej')
      result = false
    }
    if (date.length === 0) {
      errorsList.push('Data ważności jest pusta')
      result = false
    } else if (!Validator.expirationDate(date).isPotentiallyValid) {
      errorsList.push('Niepoprawna data ważności')
      result = false
    }
    if (cvc.length === 0) {
      errorsList.push('Numer CVC jest pusty')
      result = false
    } else if (!Validator.cvv(cvc).isPotentiallyValid || cvc.length < 3) {
      errorsList.push('Niepoprawny kod CVC')
      result = false
    }

    return {result: result, errors: errorsList}
  }

  const parseErrors = errors => {
    const content = errors.map(err => `<li>${err}</li>`).join('')
    return `<ul style="color: red">${content}</ul>`
  }

  const commitHandler = () => {
    const validation = validate()
    if (validation.result) {
      let timerInterval
      Swal.fire({
        title: 'Ładowanie',
        timer: 1000,
        onBeforeOpen: () => {
          Swal.showLoading()
        },
        onClose: () => {
          clearInterval(timerInterval)
        }
      }).then(() => {
        Swal.fire({
          type: 'error',
          title: 'Usługa tymczasowo niedostępna'
        })
      })
    } else {
      Swal.fire({
        type: 'error',
        title: 'Dane niepoprawne',
        html: parseErrors(validation.errors)
      })
    }
  }

  const changeHandler = event => {
    const allowed = ['0','1','2','3','4','5','6','7','8','9']

    if (event.target.name === 'number' && allowed.includes(event.target.value[event.target.value.length-1])) {
      setNumber(event.target.value.substring(0,30))
    }
    if (event.target.name === 'date') {
      if (allowed.concat('/').includes(event.target.value[event.target.value.length-1]) || event.target.value.length === 0) {
        setDate(event.target.value.substring(0,5))
      }
    }
    if (event.target.name === 'cvc' && allowed.includes(event.target.value[event.target.value.length-1])) {
      setCvc(event.target.value.substring(0,3))
    }
  }

  return (
    <div className="MainWithGradient">
      <div className="PaymentForm">
        <h2>Wprowadź dane</h2>
        <p>Opłata miesięczna 9,99 PLN</p>
        <div className="Payment-box">
          <TextField
            type="text"
            label="Numer karty kredytowej"
            placeholder="00000000000000000000000000"
            value={number}
            name="number"
            onChange={changeHandler}
          />
        </div>
        <div className="Payment-box">
          <TextField
            type="text"
            label="Data ważności"
            placeholder="00/00"
            value={date}
            name="date"
            onChange={changeHandler}
          />
        </div>
        <div className="Payment-box">
          <TextField
            type="text"
            label="Numer CVC"
            placeholder="000"
            value={cvc}
            name="cvc"
            onChange={changeHandler}
          />
        </div>
        <button onClick={commitHandler}>Potwierdź</button>
        <button onClick={() => props.history.push('/profile')}>Wróć</button>
        <p style={{fontSize: '12px'}}>Opłata naliczana jest cyklicznie</p>
        <div style={{marginBottom: '20px'}}><Link href='https://chargelity.pl'>Więcej informacji</Link></div>
      </div>
    </div>
  )
}

export default withRouter(Payment)