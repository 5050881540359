export default {
	AUTH: 0,
	LOGOUT: 1,
	SET_NEW_MESSAGES: 2,
	CLEAR_NEW_MESSAGES: 3,
	SHOW_NOTIFICATION: 4,
	HIDE_NOTIFICATION: 5,
  SET_CONVERSATION_GETTER: 6,
  CHANGE_AVATAR: 7,
  GET_POSITION: 8,
	SET_RESERVATION: 9,
	SET_CHARGERS: 10
}