import React from 'react';

import ReportChargerSelect from "./ReportChargerInput/ReportChargerSelect";

const ReportChargerCords = props => {
    return (
        <div className='ReportChargerCordsBox MainWithGradient' style={{ left: props.next ? "0%" : "-100%" }}>
        <div className='ReportChargerCordsContent'>
        <div className='Cords'>
            <div>LOKALIZACJA</div>
            <div>{props.lat} / {props.lng}</div>
        </div>
        <ReportChargerSelect
          plugs={props.plugs}
          change={props.change}
          value={props.value}
        />
        </div>
        <div>
        <button onClick={props.AcceptCords}>dalej</button>
        </div>
        </div>
    )
}

export default ReportChargerCords;