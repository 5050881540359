import React, { Component } from 'react';
import Api from '../../HOC/Api';
import Swal from 'sweetalert2';
import { withStyles } from "@material-ui/core/styles";
import Progress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import Actions from '../../HOC/store/actions';
import '../../Style/Cars/Cars.css';
import defaultCar from '../../assets/images/default_car.png';
import EditButton from '../../assets/images/editbnt.svg'
import RemoveButton from '../../assets/images/removebnt.svg'
import Add from '@material-ui/icons/AddRounded'

class Cars extends Component
{
	constructor(props)
	{
		super(props);

		this.modelInput = React.createRef();
		this.colorInput = React.createRef();
		this.beginPlateInput = React.createRef();
		this.endPlateInput = React.createRef();
		this.modelCircle = React.createRef();
		this.colorCircle = React.createRef();
	}

	state = {
		addNewCar: false,
		editCar: null,
		loading: false,
		sending: false,
		carModels: [],
		carColors: [],
		cars: []
	}

	componentDidMount = () => {
    	this.getCarModelsAndColors();
    	this.getCars();
	}

	getCarModelsAndColors = async () => {
		const result = await Api.get('/car_models');

		if(result.code === 200)
			this.setState({
				carModels: result.data.car_models,
				carColors: result.data.car_colors
			});
	}

	getCars = async () => {
		this.setState({ loading: true });
		const result = await Api.get('/cars')
		
		if(result.code === 200)
			this.setState({ cars: result.data.cars});
			
		this.setState({ loading: false });
	}

	addCar = async () => {
		const data = {
			car_model_id: this.modelInput.current.value === -1 ? null : this.modelInput.current.value,
			car_color_id: this.colorInput.current.value === -1 ? null : this.colorInput.current.value,
			plate_number: (`${this.beginPlateInput.current.value}-${this.endPlateInput.current.value}`).toUpperCase()
		}

		if(data.car_model_id === '')
			this.props.showNotification(global.translate('Choose car model'));

		else if(data.car_color_id === '')
			this.props.showNotification(global.translate('Choose car color'));

		else if(data.plate_number.length !== 5)
			this.props.showNotification(global.translate('Fill first two and last two letters of car plate'));

		else {
			this.setState({ sending: true });

			const result = await Api.post('/cars', data);

			this.setState({ sending: false });

			if(result.code === 201){
				this.props.showNotification(global.translate('The car has been added'));
				this.setState({ addNewCar: false });
				this.getCars();
			} else {
				this.props.showNotification(global.translate('Car adding error'));
			}
		}
	}

	initAddCar = (id) => {
		this.modelInput.current.value = '';
		this.colorInput.current.value = '';
		this.beginPlateInput.current.value = '';
		this.endPlateInput.current.value = '';
		this.setColor();
		this.setModel();

		this.setState({addNewCar: true });
	}

	initEditCar = (id) => {
		const car = this.state.cars.find((item) => item.id === id);

		if(!car)
			return;

		this.modelInput.current.value = car.car_model_id || -1;
		this.colorInput.current.value = car.car_color_id || -1;
		this.beginPlateInput.current.value = car.plate_number.substring(0, 2);
		this.endPlateInput.current.value = car.plate_number.substring(3, 5);
		this.setColor();
		this.setModel();

		this.setState({ editCar: car });
	}

	editCar = async () => {
		if(this.state.editCar == null)
			return;

		this.setState({ sending: true });

		const result = await Api.patch(`/cars/${this.state.editCar.id}`, {
			car_color_id: this.colorInput.current.value,
			car_model_id: this.modelInput.current.value,
			plate_number: `${this.beginPlateInput.current.value}-${this.endPlateInput.current.value}`
		});

		if(result.code === 201){
			this.props.showNotification(global.translate('Car edited'));
			this.setState({ editCar: null });
			this.getCars();
		} else {
			this.props.showNotification(global.translate('Car update error'));
		}

		this.setState({ sending: false });
	}

	removeCar = (id) => {
		const car = this.state.cars.find((item) => item.id === id);

		if(!car)
			return;

		Swal.fire({
			title: global.translate('Confirm deletion'),
			text: `${global.translate('Are you sure You want to delete')} ${car.car_model}?`,
			type: 'warning',
			showCancelButton: true,
			confirmButtonText: global.translate('Delete'),
			cancelButtonText: global.translate('Cancel')
		}).then(async (result) => {
			if (result.value) {
				this.setState({ sending: true });
				
				const result = await Api.delete(`/cars/${id}`);
				if(result.code === 200){
					this.props.showNotification(global.translate('The car has been deleted'));
					this.getCars();
				} else {
					this.props.showNotification(global.translate('Car deleting error'));
				}

				this.setState({ sending: false });
			}
		});
	}

	maxTwoChars = (event) => {
		event.target.value = event.target.value.substring(0, 2);
	}

	setColor = () => {
		const colorId = this.colorInput.current.value;

		if(!colorId) {
			this.colorCircle.current.style.backgroundColor = '#0000';
			this.colorCircle.current.style.color = '#0000';
		}
		else if(colorId === '-1') {
			this.colorCircle.current.style.backgroundColor = 'white';
			this.colorCircle.current.style.color = 'black';
		} else {
			const color = this.state.carColors.find(item => item.id.toString() === colorId).color;
			this.colorCircle.current.style.backgroundColor = color;
			this.colorCircle.current.style.color = '#0000';
		}
	}

	setModel = () => {
		const modelId = this.modelInput.current.value;

		if(!modelId) {
			this.modelCircle.current.style.backgroundColor = '#0000';
			this.modelCircle.current.style.color = '#0000';
			this.modelCircle.current.style.backgroundImage = 'none';
		}
		else if(modelId === '-1') {
			this.modelCircle.current.style.backgroundColor = 'white';
			this.modelCircle.current.style.color = 'black';
			this.modelCircle.current.style.backgroundImage = 'none';
		} else {
			const logo = this.state.carModels.find(item => item.id.toString() === modelId).car_model;
			this.modelCircle.current.style.backgroundColor = 'white';
			this.modelCircle.current.style.color = '#0000';
			this.modelCircle.current.style.backgroundImage = `url(${logo == null ? defaultCar : logo})`;
		}
	}

	render = () => (
		<div className={`Cars MainWithGradient ${this.state.addNewCar || this.state.editCar ? 'OpenedDetails' : 'OpenedList'}`}>
			<div>
				{
					!this.state.loading && !this.state.sending ? null :
					<Progress className='CarsLoading' classes={{ root: this.props.classes.progress }} />
				}
				<div className="CarsList">
					<h1>{global.translate('Your cars')}</h1>
					<div className='MyCars'>
					{
						this.state.cars.length === 0 ? <i>{global.translate('None')}</i> :
						this.state.cars.map((item) =>
						(
							<div className='Car' key={item.id}>
							<div className='ColorSquare' style={{ background: item.color_value || 'white' }}>
												{item.color_value == null ? '?' : null}
											</div>
								<img alt='car icon' src={item.icon == null ? defaultCar : item.icon} />
								<div className='CarInfo'>
									<div className='CarName'>
										{item.car_model || 'Inny'}
									</div>
									<div>
										<div className='CarPlate'>
											<div>
											<div className='BeginNumber'>{item.plate_number.substring(0, 2)}</div>
											<div className='Dots'>...</div>
											<div className='EndNumber'>{item.plate_number.substring(3, 5)}</div>
											</div>
										</div>
									</div>
								</div>
								<div className='EditBtn' onClick={() => this.initEditCar(item.id)}>
								<img src={EditButton} alt="IMG"/>
								</div>
								<div className='RemoveBtn' onClick={() => this.removeCar(item.id)}>
									<img src={RemoveButton}  alt="IMG"/>
								</div>
							</div>
						))
					}
					</div>
					<button className="AddNewCarBnt" onClick={this.initAddCar}>
						<Add />
					</button>
				</div>
				<div className="CarsDetails">
					<div>
					<h1>
						{this.state.editCar ? global.translate('Edit car') : global.translate('Add car')}
					</h1>
					<div className="CarCheckForm">
					<div className='FormField'>
						<div>{global.translate('Model')}</div>
						<select defaultValue='' onChange={this.setModel} ref={this.modelInput}>
							<option value='' disabled>{global.translate('Choose model')}</option>
							{
								this.state.carModels.sort((i1, i2) => i1.brand > i2.brand ? 1 : -1).map((item) =>
								(
									<option value={item.id} key={item.id}>
										{`${item.brand} ${item.name}`}
									</option>
								))
							}
							<option value='-1'>{global.translate('Other')}</option>
						</select>
						<div className='Circle' ref={this.modelCircle}>?</div>
					</div>
					<div className='FormField'>
						<div>{global.translate('Color')}</div>
						<select defaultValue='' onChange={this.setColor} ref={this.colorInput}>
							<option value='' disabled>{global.translate('Choose color')}</option>
							{
								this.state.carColors.sort((i1, i2) => i1.name > i2.name ? 1 : -1).map((item) =>
								(
									<option value={item.id} key={item.id}>
										{item.name}
									</option>
								))
							}
							<option value='-1'>{global.translate('Other')}</option>
						</select>
						<div className='Circle' ref={this.colorCircle}>?</div>
					</div>
					<div className='FormField PlateNumber'>
						<div>
							{global.translate('Car plate')}<br />
							<p>{global.translate('First and last two symbols')}</p>
						</div>
						<div className='PlateImage'>
							<div>
							<input type='text' onChange={this.maxTwoChars} className='FirstPart' ref={this.beginPlateInput} />
							<div><p>...</p></div>
							<input type='text' onChange={this.maxTwoChars} className='SecondPart' ref={this.endPlateInput} />
							</div>
						</div>
					</div>
					</div>
					</div>
					<div className="ButtonsOptions">
          <button onClick={() => this.setState({ addNewCar: false, editCar: null })} className="GoBackButton">{global.translate('Back')}</button>
					<button onClick={this.state.editCar ? this.editCar : this.addCar}>
						{ this.state.editCar ? global.translate('Save') : global.translate('Add') }
					</button>
					</div>
				</div>
			</div>
		</div>
  	)
}

const DTP = dispatch => ({
	showNotification: (text) => dispatch({ type: Actions.SHOW_NOTIFICATION, text: text })
  });

const styles = theme => ({
	progress: {
		margin: theme.spacing(2)
	}
})

export default withStyles(styles)(connect(null, DTP)(Cars));