import React from "react";
import { Link } from "react-router-dom";

import "../../Style/ReportCharger/ReportCharger.css";

import ReportChargerInput from "./ReportChargerInput/ReportChargerInput";

import ChevronRight from "@material-ui/icons/ChevronRightRounded";
import ChevronLeft from "@material-ui/icons/ChevronLeftRounded"
import Plus from '../../assets/images/plus.svg'
import Minus from '../../assets/images/minus.svg'

const reportChargerList = props => {

  const clickHandler = event => {
    let current = props[event.target.name].current
    if (event.target.alt === 'minus' && current.value > 0) {
      current.value = parseInt(current.value) - 1
    }
    if (event.target.alt === 'plus' && current.value < parseInt(current.max)) {
      current.value = parseInt(current.value) + 1
    }
  }

  return (
    <div
      className="ReportChargerListBox MainWithGradient"
      style={{ left: props.next ? "0%" : "100%" }}
    >
      <div className="ReportChargerTitleBox">
        <div className="ReportChargerTitle">Zgłoszenie terminalu</div>
      </div>
      <div className="ReportChargerContent">
        {Object.keys(props.inputList).map(item => (
          <div key={props.inputList[item].placeholder}>
            <ReportChargerInput
              type={props.inputList[item].type}
              name={props.inputList[item].name}
              inputRef={props.inputList[item].ref}
              disabled={props.inputList[item].disabled}
            />
          </div>
        ))}
        <div className="ReportChargerHourBox">
        <div className="TitleHourOpen">Godziny otwarcia</div>
          <div className="ReportChargerHourContentFrom">
              <div>
              <img alt='minus' name='OpenChargerHour' onClick={clickHandler} src={Minus}/>
                <input type="number" defaultValue={0} max="23" ref={props.OpenChargerHour}  disabled/>
              <img alt='plus' name='OpenChargerHour' onClick={clickHandler} src={Plus}/>
              <p>GODZINY</p>
              </div>
              <div>
              <img alt='minus' name='OpenChargerMinutes' onClick={clickHandler} src={Minus}/>
                <input type="number" defaultValue={0} max="59" ref={props.OpenChargerMinutes} disabled/>
              <img alt='plus' name='OpenChargerMinutes' onClick={clickHandler} src={Plus}/>
              <p>MINUTY</p>
              </div>
          </div>
          <div className="TitleHourOpen">Godziny Zamknięcia</div>
            <div className="ReportChargerHourContentTo">
            <div>
            <img alt='minus' name='CloseChargerHour' onClick={clickHandler} src={Minus}/>
              <input type="number" defaultValue={0} max="23" ref={props.CloseChargerHour} disabled/>
            <img alt='plus' name='CloseChargerHour' onClick={clickHandler} src={Plus}/>
            <p>GODZINY</p>
            </div>
            <div>
            <img alt='minus' name='CloseChargerMinutes' onClick={clickHandler} src={Minus}/>
              <input type="number" defaultValue={0} max="59" ref={props.CloseChargerMinutes} disabled />
            <img alt='plus' name='CloseChargerMinutes' onClick={clickHandler} src={Plus}/>
            <p>MINUTY</p>
            </div>
          </div>
        </div>
        <div className='ReportChargerDescBox'><p>Opis terminalu</p>
        <textarea ref={props.ChargerDescription}></textarea>
        </div>
      </div>
      <div className="ReportChargerButtonBox">
      <div className="CancelChargerButton ">
          <button onClick={props.cancel}>
            Wróć
          </button>
        </div>
        <div className="ReportChargerButton">
          <Link to='/home'>
          <button onClick={props.clicked}>
            Zgłoś
          </button>
          </Link>
        </div>
        </div>
    </div>
  );
};

export default reportChargerList;
       