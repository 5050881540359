import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import "../../../../Style/ChargerProfile/ChargerComponents/ChargerReservation/ChargerReservation.css";

import defaultAvatar from '../../../../assets/images/avatar.svg';
import Battery from "@material-ui/icons/BatteryChargingFull";
import Close from "@material-ui/icons/CloseRounded";

const styles = theme => ({
  iconReservation: {
    fontSize: "60px",
    color: "#FF0"
  }
});

const reservations = props => {
  const { classes } = props;
  return (
    <div>
      {(props.charger.reservations || []).map(item => (
        <div
          className={
            item.is_current === true
              ? "Reservations ReservationCurrent"
              : "Reservations"
          }
          key={item.id}
        >
          {item.is_current === true ? (
            <div className="TitleCurrentReservation">
              <div>Aktualne Ładowanie</div>
            </div>
          ) : null}
          <div className="ReservationIcon">
            {props.currentUser.id != item.user_id ? (<img src={item.avatar || defaultAvatar} alt='avatar'/>) : <Battery classes={{ root: classes.iconReservation }} />}
          </div>
          <div className="ReservationDesc">
                  {props.currentUser.id != item.user_id ? (<div className='reservation-dates'>Łukasz</div>) : null}
                  <div className='reservation-name'>{item.name}</div>
                  <div className='reservation-dates'>{item.get_start + ' - ' + item.get_end}</div>
          </div>
          {props.currentUser.id === item.user_id ? (
            <div className="ReservationCanceled">
              <Close onClick={() => props.cancelClicked(item.id)} />
            </div>
          ) : null}
        </div>
      ))}
    </div>
  );
};

const STP = state => ({
  currentUser: state.currentUser
});

export default connect(
  STP,
  null
)(withStyles(styles)(reservations));
