import React from 'react';
import PropTypes from 'prop-types';

import Battery from '@material-ui/icons/BatteryChargingFull';
import Close from '@material-ui/icons/CloseRounded';

const UserReservationItem = ({ reservation, onClick, onCancelClick }) => (
  <div
    className={
      reservation.is_current === true
        ? 'UserReservations UserCurrentReservations'
        : 'UserReservations'
    }
    key={reservation.id}
    onClick={() => onClick(reservation)}
  >
    <div className="UserReservationIcon">
      <Battery />
    </div>
    <div className="UserReservationDesc">
      <div className="reservation-name">{reservation.name}</div>
      <div className="reservation-dates">{`${reservation.get_start} - ${reservation.get_end}`}</div>
    </div>
    {onCancelClick && (
      <div className="UserReservationCanceled">
        <Close onClick={event => onCancelClick(event, reservation.id)} />
      </div>
    )}
  </div>
);

UserReservationItem.propTypes = {
  reservation: PropTypes.shape({
    is_current: PropTypes.bool,
    id: PropTypes.number,
    name: PropTypes.string,
    get_start: PropTypes.string,
    get_end: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func,
};

export default UserReservationItem;
