import React from 'react';
import { connect } from 'react-redux';
import Progress from '@material-ui/core/CircularProgress';
import Send from '../../../../assets/images/send-comment.svg';
import Delete from '@material-ui/icons/CloseRounded';

import '../../../../Style/ChargerProfile/ChargerComponents/ChargerComment/ChargerComment.css';
import defaultAvatar from '../../../../assets/images/avatar.svg';
import WithOutLike from '../../../../assets/images/like.svg'
import Like from '../../../../assets/images/like-active.svg'

const chargerComment = (props) => {
  const sendComentKeyDownHandler = (event) => {
    if (event.keyCode === 13) props.sendComment();
  };

  return (
    <div className="ChargerCommentBox">
      <div className="ChargerCommentMessage">
        <div className="ChargerCommentInput">
          <input
            type="text"
            placeholder={global.translate('Comment')}
            onKeyDown={sendComentKeyDownHandler}
            ref={props.newComment}
          />
          <img src={Send} alt='img' onClick={props.sendComment} />
        </div>
      </div>
      {(props.charger.comments || []).length === 0 ? (
        <div className="ChargerNoComments">
          <i>{global.translate('No comments')}</i>
        </div>
      ) : (
        <div>
          <div>{props.isSendingComment ? <Progress className="LoadingComment" /> : null}</div>
          <div className="Comments">
            <div className="ChargerCommentComments">
              {(props.charger.comments || []).map(item => (
                <div
                  className={`ChargerCommentContent ${
                    props.currentUser.id === item.user_id ? 'ChargerUserComment' : ''
                  }`}
                  key={item.id}
                >
                  <div className="ChargerCommentText">
                  <img alt="avatar" src={item.avatar || defaultAvatar} />
                    <p>
                      {item.login}
                      {' '}
                      {item.created_at}
                    </p>
                  </div>
                  <div className='ChargerCommentContentAndLikes'>
                    <div className='ChargerCommentComment'>
                    <p>
                        {item.content}
                      </p>
                    </div>
                    <div className="LikeComment" >
                      {props.currentUser.id === item.user_id ? <Delete onClick={() => props.deleteComment(item.id)}/> : ( item.isliked === true ? <img alt='img' src={Like} onClick={() => props.likeComment(item.id)}/> : <img alt='img' src={WithOutLike} onClick={() => props.likeComment(item.id)}/>)}
                      <div className="NumberOfLikes">Likes: {item.likes}</div>
                    </div>
                  </div>
                  {/* <div
                    className={
                      props.currentUser.id === item.user_id ? 'DeleteComent' : 'NotYourComment'
                    }
                    onClick={() => props.deleteComment(item.id)}
                  >
                    <Delete />
                  </div> */}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const STP = state => ({
  currentUser: state.currentUser,
});

export default connect(
  STP,
  null,
)(chargerComment);
