import React, { Component } from 'react';
import MessagesReceiversList from './MessagesReceiversList';
import Conversation from './Conversation/Conversation';

import { connect } from 'react-redux';

import '../../Style/Messages/Messages.css';
import Api from '../../HOC/Api';
import Actions from '../../HOC/store/actions';
import Permission from '../../HOC/permission';
import defaultAvatar from '../../assets/images/avatar.svg';

class Messages extends Component
{
	constructor(props)
	{
		super(props);
		this.newMessageInput = React.createRef();
		this.conversationListStart = React.createRef();
		this.conversationListEnd = React.createRef();
	}

	static tmpId = -1;

	gettingInterval = null;

	get newMessageValue() { return this.newMessageInput.current.value.trim(); }

	clearMessageValue = () => this.newMessageInput.current.value = '';

	getConversations = async (withLoading = true) => {
		if(withLoading)
			this.setState({ loading: true });

		const conversations = await Api.get('/conversations');

		

		if(conversations.code === 200){
			const sortedConversations = conversations.data.conversations.sort((i1, i2) => {
				if(i1.unread_messages && !i2.unread_messages)
					return -1;
			
				if(!i1.unread_messages && i2.unread_messages)
					return 1;
			
				return i1.last_date < i2.last_date ? 1 : -1;
			});

			this.setState({
				loading: false,
				conversations: sortedConversations
			});
		}
		else
			this.setState({ loading: false });
	}

	addMessage = (message) => {
		const msg = {
			...message,
			new: true,
			sending: true
		};

		const conversation = {...this.state.openedConversation}
		const messages = [...conversation.messages];

		messages.push(msg);

		conversation.messages = messages;

		this.setState({openedConversation: conversation});
	}

	markAsSended = (messageId, messageNewId) => {
		const conversation = {...this.state.openedConversation}
		const messages = [...conversation.messages];

		const index = messages.findIndex((item) => item.id === messageId);

		messages[index] = {
			...messages[index],
			id: messageNewId,
			sending: false,
			new: false
		}

		conversation.messages = messages;

		this.setState({ openedConversation: conversation });
	} 

	state = {
		isConversationOpen: false,
		conversations: [],
		openedConversation: null,
		loading: false,
		loadingOld: false,
		conversationLoading: false,
		messageCount: 0
	}

	openConservationHandler = async (conversation) => {
		const conv = {
			id: conversation.id,
			user_id: conversation.user_id,
			name: conversation.nick,
			avatar: conversation.avatar || defaultAvatar,
			messages: [],
			allMessages: false
		};

		this.setState({
			isConversationOpen: true,
			conversationLoading: true,
			openedConversation: conv
		});

		this.props.history.push(`/messages/${conversation.id}`);

		const result = await Api.get(`/conversations/${conversation.id}`);

		if(result.code === 200){
			conv.messages = result.data.messages;

			const conversations = [...this.state.conversations];
			const index = conversations.findIndex((item) => item.id === conversation.id);

			conversations[index] = {
				...conversations[index],
				unread_messages: false
			}

			this.setState({
				conversationLoading: false,
				conversations: conversations,
				openedConversation: {...conv},
				messageCount: result.data.message_count
			});

			const MessagesIdsToMatchRead = result.data.messages.filter((item) => item.is_read === false).map((item)=> item.id);

			if(MessagesIdsToMatchRead.length !== 0)
				Api.patch('/messages/read', {
					array: MessagesIdsToMatchRead
				});

			this.gettingInterval = setInterval(this.getNewMessages, 3000);
		}
		else
			this.setState({ conversationLoading: false });
	}

	getNewMessages = async () => {
		if(this.state.openedConversation == null)
			return;

		const maxOtherId = this.state.openedConversation.messages.reduce((prevMax, item) => {
			return Math.max(prevMax, item.id)
		}, 0);

		const result = await Api.get(`/conversations/${this.state.openedConversation.id}/new_messages?last_id=${maxOtherId}`);

		if(result.code === 200){
			const currentMessagesIds = this.state.openedConversation.messages.map((item) => item.id);
			const newMessagesIds = result.data.messages;

			const messagesToAdd = newMessagesIds.filter((item) => item.is_my === false && !currentMessagesIds.includes(item.id));

			messagesToAdd.forEach((item) => this.addMessage(item));

			const MessagesIdsToMatchRead = newMessagesIds.map((item) => item.id);

			if(MessagesIdsToMatchRead.length !== 0)
				Api.patch('/messages/read', {
					array: MessagesIdsToMatchRead
				});
		}
	}

	getOldMessages = async () => {
		if(this.state.openedConversation == null)
			return;

		this.setState({ conversationLoading: true, loadingOld: true });

		const minId = this.state.openedConversation.messages.reduce((prevMin, item) => {
			return Math.min(prevMin, item.id)
		}, Number.POSITIVE_INFINITY);

		const result = await Api.get(`/conversations/${this.state.openedConversation.id}/old_messages?last_id=${minId}`);

		if(result.code === 200){
			const openedConversation = {...this.state.openedConversation};
			let messages = [...openedConversation.messages];

			messages = messages.concat(result.data.messages);

			messages.sort((a, b) => a.id > b.id ? 1 : -1);

			openedConversation.messages = messages;
			this.setState({ openedConversation: openedConversation });
		}

		if(result.code === 204){
			let openedConversation = {...this.state.openedConversation};
			openedConversation = {...this.state.openedConversation};
			openedConversation.allMessages = true;
			this.setState({ openedConversation: openedConversation });
		}

		this.setState({ conversationLoading: false, loadingOld: false });
	}

	closeConservationHandler = () => {
		this.setState({isConversationOpen: false});
		clearInterval(this.gettingInterval);
		this.props.history.push('/messages');
		this.gettingInterval = null;
		this.getConversations(false);
	}

	sendMessageHandler = async () => {
		const message = this.newMessageValue;

		if(message === '' || this.state.openedConversation == null)
			return;

		const temporaryMessage = {
			id: Messages.tmpId--,
			is_my: true,
			content: message,
			time: global.getCurrentTimeToMinutes()
		}

		this.addMessage(temporaryMessage);
		this.clearMessageValue();

		const result = await Api.post('/messages', {
			content: message,
			conversation_id: this.state.openedConversation.id,
			receiver_id: this.state.openedConversation.user_id
		});

		if(result.code === 201)
			setTimeout(() => this.markAsSended(temporaryMessage.id, result.data.message.id), 500);
	}

	componentDidMount = async () => {
		if(this.props.newMessages === true)
			this.props.onEntryMessages();

		await this.getConversations();

		if(this.props.match.params.id !== undefined){
			const conversation = this.state.conversations.find((item) => item.id.toString() === this.props.match.params.id);

			if(conversation != null)
				this.openConservationHandler(conversation);
		}

		this.props.setConversationGetter(() => this.getConversations(false));
	}

	componentWillUnmount = () =>{
		clearInterval(this.gettingInterval);
		this.props.setConversationGetter(null);
	} 

	componentDidUpdate = (oldProps, oldState) =>
	{
		if(this.props.match.params.id === undefined && this.state.isConversationOpen === true)
			this.closeConservationHandler();

		if(oldProps.newMessagesTrigger !== this.props.newMessagesTrigger)
			console.log('POBIERZ WIADOMOŚCI');

		if(this.state.openedConversation == null || oldState.openedConversation == null)
			return;

		if(oldState.conversationLoading === true || oldState.openedConversation.messages.length !== this.state.openedConversation.messages.length)
		{
			if(oldState.loadingOld === true)
				this.conversationListStart.current.scrollIntoView({ behaviour: 'smooth' });
			else
				this.conversationListEnd.current.scrollIntoView({ behaviour: 'smooth' });
		}
	}

	render() {
		return (
			<div className='MessagesContent MainWithGradient'>
				<Permission userRequired />
				{
					this.props.currentUser == null ? null :
					<Conversation
						open={this.state.isConversationOpen}
						loading={this.state.conversationLoading}
						conversation={this.state.openedConversation}
						conversationListStart={this.conversationListStart}
						conversationListEnd={this.conversationListEnd}
						onCloseConversation={this.closeConservationHandler}
						onSendMessage={this.sendMessageHandler}
						onGetOldMessages={this.getOldMessages}
						newMessageInputRef={this.newMessageInput}
						messageCount={this.state.messageCount}
					/>
				}
				<MessagesReceiversList 
					open={!this.state.isConversationOpen}
					loading={this.state.loading}
					conversations={this.state.conversations} 
					onOpenConversation={this.openConservationHandler}
				/>
			</div>
		);
	}
}

const STP = (state) => ({
	currentUser: state.currentUser,
	newMessages: state.newMessages,
	newMessagesTrigger: state.newMessagesTrigger
});

const DTP = (dispatch) => ({
	onEntryMessages: () => dispatch({ type: Actions.CLEAR_NEW_MESSAGES }),
	setConversationGetter: (getter) => dispatch({ type: Actions.SET_CONVERSATION_GETTER, getter: getter })
})

export default connect(STP, DTP)(Messages);