import React, { useState, useEffect } from 'react';
import { withStyles } from "@material-ui/core/styles";
import ConversationMessage from './ConversationMessage';

import ArrowBack from "@material-ui/icons/ArrowBackOutlined";
import Send from "../../../assets/images/send-comment.svg";
import Progress from '@material-ui/core/CircularProgress';

import '../../../Style/Messages/Conversation.css';
import GoogleMapReact from 'google-map-react';

const styles = theme => ({
	arrowBack: {
		fontSize: '30px',
		position: 'absolute',
		left: 0,
		cursor: 'pointer'
	},
	send: {
		fontSize: '40px',
		cursor: 'pointer',
		color: '#00AAFF'
	},
	progress: {
		margin: theme.spacing(2)
	}
})

const Conversation = (props) => {
  const [emptyList, setEmptyList] = useState(false)

  useEffect(() => {
    if (props.conversation) {
      setEmptyList(props.conversation.messages.length === 0)
    }
  }, [setEmptyList, props.conversation])

	const { classes } = props;

	const newMessageInputKeyDownHandler = (event) => {
		if(event.keyCode === 13)
			props.onSendMessage();
  }
  
  console.log(emptyList)
  	const downloadMessages = props.messageCount > 20 ? (
		<div style={{ color: 'gray', cursor: 'pointer', display: 'table', margin: 'auto' }}>
			<i onClick={props.onGetOldMessages}>{global.translate('Download previous messages')}</i>
		</div>
	  ) : null

	return props.conversation == null ? <div className='ConversationArea' style={{left: props.open ? '0%' : '100%'}} /> : (
		<div className='ConversationArea' style={{left: props.open ? '0%' : '100%'}}>
			<div className='ConversationTitle'>
				<ArrowBack classes={{ root: classes.arrowBack }} onClick={props.onCloseConversation} />
				<div className='ConversationTitleName'>{props.conversation.name || ''}</div>
			</div>
			<div className='ConversationMessagesList'>
				<div ref={props.conversationListStart}></div>
				{
					props.loading !== true ? null :
					<Progress className="ConversationLoading" classes={{ root: classes.progress }} />
				}
				{ emptyList ? null : ( 
					(props.loading === true || props.conversation.allMessages === true) ? null : downloadMessages)
				
				}
				{
					(props.conversation.messages || []).map((item) => (
						<ConversationMessage
							key={item.id}
							isMyMessage={item.is_my}
							avatar={props.conversation.avatar}
							time={item.time}
							content={item.content}
							new={item.new}
							sending={item.sending}
						/>
					))
				}
				<div ref={props.conversationListEnd}></div>
			</div>
			<div className='ConversationSendArea'>
				<div className='ConversationSendInput'>
					<input ref={props.newMessageInputRef} type='text' placeholder={global.translate('Your message')} onKeyDown={newMessageInputKeyDownHandler} />
					<img src={Send} onClick={props.onSendMessage} />
				</div>
			</div>
		</div>
	);
}

export default withStyles(styles)(Conversation);