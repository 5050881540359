import React, {useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';
import Api from '../../HOC/Api';
import calc, { isWater, getProfitabiltyTextAndColorByPercent } from './Calc';
import './MapPicker.css';
import StatsImg from '../../assets/images/stats.svg';
import NewChargerMarker from '../../assets/images/new_charger.png';

const DAILY_CALCULATING_LIMIT = 3;
const MapPicker = (props) => {
  const [show, setShow] = useState(false)

	useEffect(() => {
		setShow(props.history.location.pathname === '/map_picker')
  }, [props.history.location.pathname])

  const redirectToPicker = () => {
    props.history.push(`/report_charger?lat=${props.position.lat}&lng=${props.position.lng}`);
  };

  const redirectToPaymentPlans = () => props.history.push('/payment_plans');

  const getTodayCalculations = async () => Api.get('/calculate_profitability');
  const postTodayCalculations = async () => Api.post('/calculate_profitability');

  const showCalculationResultAlert = (resultPercent) => {
    const [text, color] = getProfitabiltyTextAndColorByPercent(resultPercent);
    const title = `${global.translate('Terminal profitability')}: <span style="color:${color}">${global.translate(text)}</span>`
    const content = `${global.translate('Percentage value')}: <b style="color:${color}"> ${resultPercent}</b>`
    Swal.fire({
      imageUrl: StatsImg,
      title,
      html: content,
    });
  };

  const showErrorAlert = message => (
    Swal.fire({
      type: 'error',
      text: message,
    })
  );

  const showCalculationsLeftAlert = async (calculationsCount) => {
    const result = await Swal.fire(
      {
        type: 'warning',
        title: `${global.translate('Today calculations left')}: ${calculationsCount}`,
        text: global.translate('Make sure marker is not placed on the water.'),
        confirmButtonText: 'Continue',
        showCancelButton: true,
      },
    );
    if (result.value) {
      showCalculationAlert();
    }
  };

  const showCalculationAlert = async () => {
    let isOnWater = true;
    let calculationResult;
    const alertResult = await Swal.fire({
      title: `${global.translate('Checking terrain')}...`,
      text: global.translate('Please wait a moment'),
      timer: 6000,
      allowOutsideClick: false,
      allowEscapeKey: false,
      onBeforeOpen: () => {
        Swal.showLoading();
        setTimeout(() => {
          if (!isOnWater) {
            Swal.getTitle().textContent = `${global.translate('Calculating profitability')}...`;
            calculationResult = calc(props.position.lat, props.position.lng, props.chargers);
          } else {
            Swal.close();
            showErrorAlert(global.translate('Marker placement is invalid'));
          }
        }, 3000);
      },
      onOpen: async () => {
        isOnWater = await isWater(props.position.lat, props.position.lng);
        postTodayCalculations();
      },
    });
    if (alertResult.dismiss === Swal.DismissReason.timer) {
      showCalculationResultAlert(calculationResult[0], calculationResult[1]);
    }
  };

  const calculateProfitabilityClickHandler = async () => {
    Swal.showLoading();
    let calculationsLeft = null;
    try {
      const response = await getTodayCalculations();
      calculationsLeft = DAILY_CALCULATING_LIMIT - response.data.count;
      if (calculationsLeft <= 0) {
        showLimitReachedAllert();
      } else {
        showCalculationsLeftAlert(calculationsLeft);
      }
    } catch {
      showErrorAlert(global.translate('Error occurred. Try again later.'));
    }
  };

  const showLimitReachedAllert = async () => {
    const result = await Swal.fire({
      type: 'error',
      title: global.translate('Today calculations limit reached'),
      text: global.translate('Buy premium account or try tommorrow'),
      confirmButtonText: global.translate('Buy premium'),
      showCancelButton: true,
    });
    if (result.value) {
      redirectToPaymentPlans();
    }
  };

  const content = (
    <div className="MapPicker">
      <div className="MapAim" />
      <div className="MapPickerButton">
        <button onClick={redirectToPicker}>{global.translate('Add here')}</button>
      </div>
      <div className="CalcButton">
        <button onClick={calculateProfitabilityClickHandler}>{global.translate('Calculate profitability')}</button>
      </div>
    </div>
  );

  return show ? content : null;
};

export default withRouter(MapPicker);
