import React, { Component, Fragment } from "react";

import { withStyles } from "@material-ui/core/styles";
import Battery from "@material-ui/icons/BatteryChargingFull";

import Stats from '../../assets/images/stats.svg'
import "../../Style/UserStats/UserStats.css";
import Api from "../../HOC/Api";
import { CircularProgress } from "@material-ui/core";

const styles = theme => ({
  battery: {
    fontSize: "70px",
    color: "#00aaff",
    float: "left",
    height: "80px"
  },
  progressRoot: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  progressColor: {
    color: '#00A0FD',
  },
});

class UserStats extends Component {
  state = {
    chargingsList: [],
    allChargings: 0,
    lastMonthChargings: 0,
    lastWeekChargings: 0,
    loading: false,
  };

  componentDidMount() {
    this.getStats();
  }

  getStats = async () => {
    this.setState({ loading: true });
    const stats = await Api.get("/user/stats");

    if (stats.code === 200) {
      this.setState({
        chargingsList: stats.data.reservations,
        allChargings: stats.data.all,
        lastMonthChargings: stats.data.last_month,
        lastWeekChargings: stats.data.last_week,
        loading: false,
      });
    } else {
      this.setState({ loading: false });
    }
  };

  render() {
    const { classes } = this.props;
    const { allChargings, lastMonthChargings, lastWeekChargings, chargingsList, loading } = this.state;

    return (
      <div className="UserStatsArea MainWithGradient">
        {loading ? (
          <CircularProgress classes={{
            root: classes.progressRoot,
            colorPrimary: classes.progressColor,
          }}
          />
        ) : (
          <Fragment>
            <div className="UserStatsChargingArea">
              <div className="Statbox">
                <img alt='stats' src={Stats}/>
                <div>
                  <div className="stat"><div>{global.translate('All time')}</div><div>{allChargings}</div></div>
                  <div className="stat"><div>{global.translate('Last month')}</div><div>{lastMonthChargings}</div></div>
                  <div className="stat"><div>{global.translate('Last week')}</div><div>{lastWeekChargings}</div></div>
                </div>
              </div>
            </div>
            <div className="ChargingList">
              {chargingsList.length === 0 ? (
                <div style={{ marginTop: "30px", color: "#FFF" }}>
                  <i>{global.translate('None')}</i>
                </div>
              ) : (
                chargingsList.map(item => (
                  <div key={item.id} className="UserStatsChargingArea">
                    <Battery classes={{ root: classes.battery }} />
                    <div className="UserChargingHistory">
                    <div className="info">{item.name}</div>
                    <div className="info2">
                      {item.get_start} - {item.get_end}
                    </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </Fragment>
        )
      }
      </div>
    );
  }
}

export default withStyles(styles)(UserStats);
