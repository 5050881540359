import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Progress from '@material-ui/core/CircularProgress';
import Logo from '../../assets/images/logo.png';
import Api from '../../HOC/Api';
import Actions from '../../HOC/store/actions';
import Permission from '../../HOC/permission';
import '../../Style/Register/Register.css';
import TextField from '@material-ui/core/TextField';
import Swal from 'sweetalert2';

class Register extends Component {
  constructor(props) {
    super(props);

    this.usernameInput = React.createRef();
    this.emailInput = React.createRef();
    this.passwordInput = React.createRef();
    this.password2Input = React.createRef();
  }

  register = async () => {
    const nick = this.usernameInput.current.querySelector('input').value;
    const email = this.emailInput.current.querySelector('input').value;
    const password = this.passwordInput.current.querySelector('input').value;
    const password_confirmation = this.password2Input.current.querySelector('input').value;

    if (nick === '' || email === '' || password === '' || password_confirmation === '') {
      this.props.showNotification(global.translate('Fill registration data'));
      return;
    }

    if (password !== password_confirmation) {
      this.props.showNotification(global.translate('Passwords are not the same'));
      return;
    }

    if (!this.state.checkbox) {
      this.props.showNotification(global.translate('Accept Terms of Service to continue'))
      return
    }

    this.setState({ loading: true });

    const result = await Api.post('/users', {
      user: {
        nick,
        email,
        password,
        password_confirmation,
      },
    });

    if (result.code === 201) {
      const userData = { ...result.data.user, token: result.data.token };
      this.props.onAuth(userData);
      this.props.history.push('/home');

      Swal.fire({
        title: global.translate('Choose your plan'),
        type: 'question',
        confirmButtonColor: '#009900',
        confirmButtonText: global.translate('Choose your plan'),
      }).then((result) => {
        if (result.value) {
          this.props.history.push('/payment_plans')
        } else {
          this.props.history.push('/payment')
        }
      })

    } else if (result.code === 422) {
      if ('email' in result.data.errors) {
        this.props.showNotification(
          result.data.errors.email[0] === 'has already been taken'
            ? global.translate('Given email is taken')
            : global.translate('Given email is invalid'),
        );
      } else if ('nick' in result.data.errors) {
        this.props.showNotification(global.translate('Given login is taken'));
      } else if ('password' in result.data.errors) {
        this.props.showNotification(global.translate("Password is too short"));
      }
    }

    this.setState({ loading: false });
  };

  state = {
    loading: false,
    checkbox: false
  };

  keyDownHandler = (event) => {
    if (event.keyCode === 13) this.register();
  };

  render() {
    return (
      <div className="RegisterWindow">
        <Permission userDeny />
        <div>
          {!this.state.loading ? null : (
            <Progress className="RegisterLoading" classes={{ root: this.props.classes.progress }} />
          )}
          <div className="RegisterWindowContent">
            <div className="RegisterLogo">
              <img alt="Logo" src={Logo} />
            </div>
            <div className="RegisterHeader">{global.translate('Chargelity')} </div>
            <div className="RegisterHeader2">{global.translate('SIGN UP')}</div>
            <div className="RegisterForm">
              <div>
                <TextField
                  ref={this.usernameInput}
                  label={global.translate('Login')}
                  onKeyDown={this.keyDownHandler}
                  type="text"
                />
              </div>
              <div>
                <TextField ref={this.emailInput} onKeyDown={this.keyDownHandler} label={global.translate('Email')} type="email" />
              </div>
              <div>
                <TextField ref={this.passwordInput} onKeyDown={this.keyDownHandler} label={global.translate('Password')} type="password" />
              </div>
              <div>
                <TextField
                  ref={this.password2Input}
                  onKeyDown={this.keyDownHandler}
                  label={global.translate('Confirm password')}
                  type="password"
                />
              </div>
              <div className='register-checkbox'>
                <input type='checkbox' checked={this.state.checkbox} onChange={e => this.setState({checkbox: e.target.checked})}/> 
          <div className='register-checkbox-desc'>{global.translate('I accept')} <a href='https://chargelity.eu/privacy-policy/'>{global.translate('Terms of Service')}</a></div>
              </div>
              <div className="RegisterButton">
          <button onClick={this.register}>{global.translate('SIGN UP 2')}</button>
              </div>

          <Link to="/login">{global.translate('Back to login')}</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const DTP = dispatch => ({
  onAuth: user => dispatch({
    type: Actions.AUTH,
    user,
  }),
  showNotification: text => dispatch({ type: Actions.SHOW_NOTIFICATION, text }),
});

const styles = theme => ({
  progress: {
    margin: theme.spacing(2),
  },
});

export default withStyles(styles)(
  connect(
    null,
    DTP,
  )(Register),
);
