import React from "react";
import Rating from "@material-ui/lab/Rating";

import Star from  '../../../../../assets/images/star.svg'

import "../../../../../Style/ChargerProfile/ChargerComponents/ChargerOpinion/ChargerOpinion.css";

const chargerRating = props => {
  return (
    <div className="ChargerRatingBox">
      <div className="ChargerRating">
        <div className="ChargerRatingName">
          <div>{global.translate('Speed')}</div>
        </div>
        <div className="ChargeRatingScore">
          <div>{(props.charger.rating_speed || 0).toFixed(1)}</div>
          <div>
          <Rating
            name="speed"
            value={props.votes.speed}
            onChange={(_, value) => props.onRatingChange("speed", value)}
          />
          </div>
        </div>
      </div>
      <div className="ChargerRating">
        <div className="ChargerRatingName">
          <div>{global.translate('Availibility')}</div>
        </div>
        <div className="ChargeRatingScore">
        <div>{(props.charger.rating_available || 0).toFixed(1)}</div>
          <div>
          <Rating
            name="available"
            value={props.votes.available}
            onChange={(_, value) => props.onRatingChange("available", value)}
          />
          </div>
        </div>
      </div>
      <div className="ChargerRating">
        <div className="ChargerRatingName">
          <div>{global.translate('Service')}</div>
        </div>
        <div className="ChargeRatingScore">
        <div>{(props.charger.rating_service || 0).toFixed(1)}</div>
          <div>
          <Rating
            name="service"
            value={props.votes.service}
            onChange={(_, value) => props.onRatingChange("service", value)}
          />
          </div>
        </div>
      </div>
      <div className="ChargerRating">
        <div className="ChargerRatingName">
          <div>{global.translate('Price')}</div>
        </div>
        <div className="ChargeRatingScore">
          <div>{(props.charger.rating_cost || 0).toFixed(1)}</div>
          <div>
          <Rating
            name="cost"
            value={props.votes.cost}
            onChange={(_, value) => props.onRatingChange("cost", value)}
          />
          </div>
        </div>
      </div>
    </div>
  );
};
export default chargerRating;
