/* eslint-disable no-nested-ternary */
import React from 'react';

import '../../Style/UserReservation/UserReservation.css';
import PropTypes from 'prop-types';
import UserReservationItem from './UserReservationItem';
import { withStyles, CircularProgress } from '@material-ui/core';

const styles = theme => ({
  progressRoot: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  progressColor: {
    color: '#00A0FD',
  },
});

const UserReservationsList = ({
  reservations,
  listTitle,
  onClickReservation,
  onClickCancelReservation,
  isLoading,
  classes,
}) => (
  <React.Fragment>
    <div className="UserReservationTitle">{listTitle}</div>
    {isLoading ? (
      <CircularProgress classes={{
        root: classes.progressRoot,
        colorPrimary: classes.progressColor,
      }}
      />
    ) : (reservations || []).length === 0 ? (
      <div className="UserReservationWithout">Brak rezerwacji</div>
    ) : (
      <div className="UserReservationContent">
        {(reservations || []).map(item => (
          <UserReservationItem
            key={item.id}
            reservation={item}
            onClick={onClickReservation}
            onCancelClick={onClickCancelReservation}
          />
        ))}
      </div>
    )}
  </React.Fragment>
);

UserReservationsList.propTypes = {
  reservations: PropTypes.array,
  listTitle: PropTypes.string.isRequired,
  onClickReservation: PropTypes.func,
  onClickCancelReservation: PropTypes.func,
  isLoading: PropTypes.bool,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserReservationsList);
