import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Actions from '../../HOC/store/actions';

import '../../Style/Navigation/Navigation.css';

import NavigationSearch from './NavigationSearch';
import NavigationOptions from './NavigationOptions';

import NavigationHelper from '../Map/NavigationHelper';
import Swal from 'sweetalert2';

class Navigation extends Component {
  constructor(props) {
    super(props);

    this.fromRef = React.createRef();
    this.toRef = React.createRef();
    this.helper = null;
  }

  state = {
    isClose: false,
  };

  componentWillUnmount() {
    if (this.helper) {
      this.helper.clearDirections();
    }
  };

  openOptionHandler = () => {
    this.props.history.push('/home');
  };

  onFind = () => {
    const from = this.fromRef.current.children[0].children[0].value;
    const to = this.toRef.current.children[0].children[0].value;

    if (from === '' || to === '') {
      this.props.showNotification(global.translate('Fill start and end destination'));
      return;
    }
    if (!this.helper) {
      this.initNavigationHelper();
    }
    this.setDirections(from, to);
  };

  initNavigationHelper = () => {
    if (global.maps) {
      const service = new global.maps.DirectionsService();
      const renderer = new global.maps.DirectionsRenderer();
      renderer.setMap(global.map);
      this.helper = new NavigationHelper(service, renderer);
    }
  };

  setDirections = async (from, to) => {
    const { chargers } = this.props;
    const route = await this.helper.getRouteWithChargers(from, to, 50, chargers);
    if (route) {
      this.openResultAlert(from, to, route);
    } else {
      Swal.fire(
        global.translate('Error occurred'),
        global.translate('Route not found'),
        'error'
      );
    }
  };

  openResultAlert = async (from, to, route) => {
    const link = this.helper.getGoogleMapsLinkFromRoute(route);
    const alertResult = await Swal.fire({
      title: `${from} - ${to}: ${this.helper.getDistanceFromRoute(route)}km`,
      confirmButtonText: `<a href=${link} style="text-decoration:none; color: #FFFFFF">${global.translate('Open in Google Maps')}`,
      showCancelButton: true,
    });
  };

  render() {
    return (
      <div className="NaviContent">
        <div className="NaviBox">
          <NavigationSearch open={!this.state.isClose} fromRef={this.fromRef} toRef={this.toRef} />
          <NavigationOptions
            open={this.state.isClose}
            clicked={this.openOptionHandler}
            onFind={this.onFind}
          />
        </div>
      </div>
    );
  }
}
const STP = state => ({
  chargers: state.chargers,
});

const DTP = dispatch => ({
  showNotification: text => dispatch({ type: Actions.SHOW_NOTIFICATION, text }),
});

export default withRouter(
  connect(
    STP,
    DTP,
  )(Navigation),
);
