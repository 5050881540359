import { createStore } from 'redux';
import Actions from './actions';
import Api from '../Api';
import defaultAvatar from '../../assets/images/avatar.svg';

const initialState = {
  currentUser: null,
  newMessages: false,
  newMessagesTrigger: false,
  notificationShow: false,
  notificationText: '',
  conversationGetter: null,
  pos: [500, 500],
  reservation: null,
  chargers: [],
};

let notificationTimer = null;

const reducer = (state = initialState, action) => {
  const newState = { ...state };

  switch (action.type) {
    case Actions.AUTH:
      if (!action.user.avatar) action.user.avatar = defaultAvatar;

      newState.currentUser = action.user;
      Api.currentUser = action.user;
      localStorage.setItem('userToken', action.user.token, { path: '/' });
      break;

    case Actions.LOGOUT:
      newState.currentUser = null;
      Api.currentUser = null;
      localStorage.removeItem('userToken');
      break;

    case Actions.SET_NEW_MESSAGES:
      newState.newMessages = true;
      newState.newMessagesTrigger = true;
      break;

    case Actions.CLEAR_NEW_MESSAGES:
      newState.newMessages = false;
      break;

    case Actions.SHOW_NOTIFICATION:
      newState.notificationShow = true;
      newState.notificationText = action.text;

      clearTimeout(notificationTimer);

      notificationTimer = setTimeout(
		  // eslint-disable-next-line
        () => store.dispatch({ type: Actions.HIDE_NOTIFICATION }),
        action.showTime || 2000,
      );
      break;

    case Actions.HIDE_NOTIFICATION:
      newState.notificationShow = false;
      break;

    case Actions.SET_CONVERSATION_GETTER:
      newState.conversationGetter = action.getter;
      break;

    case Actions.CHANGE_AVATAR:
      if (newState.currentUser !== null) {
        newState.currentUser.avatar = action.avatar;
      }
      break;

    case Actions.GET_POSITION:
      newState.pos = action.pos;
      break;

    case Actions.SET_RESERVATION:
      newState.reservation = action.reservation;
      break;

    case Actions.SET_CHARGERS:
      newState.chargers = action.chargers;
      break;

    default:
      break;
  }

  return newState;
};

export const store = createStore(reducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
export default reducer;
