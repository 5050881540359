import React, {Component} from 'react'
import ChecketCircle from "@material-ui/icons/CheckCircleOutline";
import NoneOption from "@material-ui/icons/HighlightOffOutlined";

import '../../Style/Profile/Profile.css';

class PaymentPlans extends Component {
    render(){
        return (<div className="Profile MainWithGradient">
        <div className="ProfileForm">
          <h2>{global.translate('Premium Account')}</h2>
          <div className="Profile-box PlanContainer">
            <div className="PlanOptions"><ChecketCircle/><div>{global.translate('Access terminals worldwide')}</div></div>
            <div className="PlanOptions"><ChecketCircle/> <div>{global.translate('No ads')}</div></div>
            <div className="PlanOptions"><ChecketCircle/> <div>{global.translate('Unlimited messages')}</div></div>
            <div className="PlanOptions"><ChecketCircle/><div>{global.translate('Possibility of adding unlimited amount of cars')}</div></div>
            <div className="PlanOptions"><ChecketCircle/><div>{global.translate('Car charging discount')}</div></div>
          </div>
          <button onClick={() => this.props.history.push('/payment')}>{global.translate('Buy Premium')}</button>
        </div>
        <div className="ProfileForm">
          <h2>{global.translate('Free Account')}</h2>
          <div className="Profile-box PlanContainer">
          <div className="PlanOptionsFree"><ChecketCircle/><div>{global.translate('Access terminals in Europe')}</div></div>
            <div className="PlanOptionsFree"><NoneOption/><div>{global.translate('Ads')}</div></div>
            <div className="PlanOptionsFree"><NoneOption/> <div>{global.translate('Add one car')}</div></div>
            <div className="PlanOptionsFree"><NoneOption/> <div>{global.translate('Limited messages')}</div></div>
          </div>
          <button onClick={() => this.props.history.push('/')}>{global.translate('Choose Free')}</button>
        </div>
      </div>)
    }
}

export default PaymentPlans;