import React from "react";

import "../../Style/Navigation/Navigation.css";

const navigationOptions = props => {
  return (
    <div className="NaviButtons">
      {!props.open ? (
        <div className="NaviButtons2">
          <button onClick={props.onFind}>{global.translate('Find route')}</button>
          <button onClick={props.clicked}>{global.translate('Cancel')}</button>
        </div>
      ) : (
        <div>{global.translate('Find route')}</div>
      )}
    </div>
  );
};

export default navigationOptions;
