import React from 'react';
import { Link } from 'react-router-dom';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
	listItemText: {
	  fontSize: "25px",
	  color: "white"
	}
	});
	

const SidebarOption = (props) =>
{
	const cssClasses = ["SidebarOptions", props.AvatarGame];
	return(
	<Link to={props.path} onClick={props.onClick}>
		<ListItem className={cssClasses.join(" ")} >
			<ListItemIcon>
				<React.Fragment>
					{
						<div className= {props.className}>
						<img src={props.icon} alt="img" />
						</div>
					}
					{
						!props.redIcon ? null :
						<div className="NewMessagesIcon">!</div>
					}
				</React.Fragment>
			</ListItemIcon>
			<ListItemText
				classes={{ primary: props.classes.listItemText }}
				primary={props.label}
			/>
		</ListItem>
	</Link>
)
}

export default withStyles(styles)(SidebarOption);