import React from 'react';

import '../../Style/Messages/Messages.css';
import defaultAvatar from '../../assets/images/avatar.svg';

const messagesReceiver = props => (
  <div onClick={props.onOpen} className="MessagesReceiver">
    <img alt="avatar" className="MessagesReceiverAvatar" src={props.avatar || defaultAvatar} />
    <div className="MessagesReceiverInfo">
      <div className="MessagesReceiverTime">{global.getTimeStringFromUnix(props.time)}</div>
      <div className="MessagesReceiverName" style={{ opacity: props.new ? 1 : 0.5 }}>
        {props.name}
      </div>
    </div>
  </div>
);

export default messagesReceiver;
